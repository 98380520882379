const Validate = {
    form: (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        return true;
    }
}
export default Validate;