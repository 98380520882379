const TransactionActions = {

    submitForm: (e) => {

    },
    setFormData: (e, campaignData) => {
        const temp = campaignData;
        const key = e.target.name;
        if (key.indexOf(".") > 0) {
            const arr = key.split(".");
            temp[arr[0]][arr[1]] = e.target.value;
        } else {
            temp[key] = e.target.value;
        }
        return temp;
    },
    // filterSingleUserTransactions: (docs, uid, trxId) => {
    //     var tempsingleUserTransactions
    //     docs.forEach((doc) => {
    //         const singleUserTransactions = doc.data();
    //         if (doc.id === uid) {
    //             tempsingleUserTransactions = singleUserTransactions[trxId]
    //         }
    //     });
    //     return tempsingleUserTransactions
    // },
    filterSingleUserTransactions: (docs, uid, trxId) => {
        var tempsingleUserTransactions
        docs.forEach((doc) => {
            const singleUserTransactions = doc.data();
            if (doc.id === uid) {
                for (const transactionId in singleUserTransactions) {
                    if (transactionId === trxId) {
                        tempsingleUserTransactions = singleUserTransactions
                    }
                }
            }
        });
        return tempsingleUserTransactions
    },
    filterSingleCampaignTransactions: (docs, uid, transactionModel, transactionId, callback) => {


        docs.forEach((doc) => {
            const singleCampaignTransactions = doc.data();
            for (const userId in singleCampaignTransactions) {
                if (userId === uid) {

                    callback(singleCampaignTransactions, doc.id, userId, transactionModel, transactionId)
                }
            }
        });
    }
}

export default TransactionActions;