export const FCMNotificationsModel = {
  title: "",
  body: "",
  tokens: [], 
};
export const NotificationsModel = {
  title: "",
  body: "",
  image: "https://www.ebigwin.com/assets/img/logo/logo.png",
};
