import React, { useContext } from "react";
import Context from "../context/Context";
import Nav from "react-bootstrap/Nav";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import generateHash from "../helpers/hash";
/*
 * @sideBarState contains sidebar items data
 * @item conains link title and icon
 * @Link routes you to specified route
 * this jsx section creates sidenavigation using sideBarState
 * The openViewPage method trigger the menuClick action in state.js
 * we can modify the sidebar text icon link routing path etc from sideBarState inside the Context/State.js.
 */

const Sidebar = () => {
  const { sideBarState, menuClick } = useContext(Context);
  const openViewPage = (e) => {
    menuClick(e.target.innerText);
  };
  return (
    <div
      className="sidebar"
      style={{ margin: "0", height: "100%", zIndex: "999" }}
    >
      <div className="sidebar-wrapper">
        <div className="logo">
          <a href="/#" className="simple-text logo-mini">
            <i className="tim-icons icon-plus"></i>
          </a>
          <Link to="/campaigns" className="simple-text logo-normal">
            <strong> E Big Win </strong>
          </Link>
        </div>

        <Nav className="flex-column ">
          {sideBarState.map((item, index) =>
            index > 0 ? (
              <Accordion key={generateHash()}>
                {/* style={{ backgroundColor: "#e14eca " }} */}
                <Card
                  style={{ backgroundColor: "transparent", cursor: "pointer" }}
                >
                  {item.subitems !== undefined ? (
                    <Accordion.Toggle as={Card.Header} eventKey={index}>
                      <strong
                        className="d-flex mb-2"
                        style={{ color: " rgba(255, 255, 255, 0.8)" }}
                      >
                        <i className={item.icon}></i>
                        <b
                          className="d-flex"
                          style={{
                            color: " rgba(255, 255, 255, 0.8)",
                            fontWeight: "800",
                          }}
                        >
                          {item.title}
                        </b>
                      </strong>
                    </Accordion.Toggle>
                  ) : (
                    <Card.Header>
                      <Link to={item.link}>
                        <div
                          className={"d-flex flex-row align-items-center"}
                          text={item.title}
                          onClick={openViewPage}
                        >
                          <i className={item.icon}></i>
                          <b
                            className="d-flex"
                            style={{
                              color: " rgba(255, 255, 255, 0.8)",
                              fontWeight: "800",
                            }}
                          >
                            {item.title}
                          </b>
                        </div>
                      </Link>
                    </Card.Header>
                  )}
                  {item.subitems === undefined ? (
                    <></>
                  ) : (
                    item.subitems.map((subitem, subIndex) => (
                      <Accordion.Collapse key={generateHash()} eventKey={index}>
                        <Card.Body>
                          <Link to={subitem.link}>
                            <span text={subitem.title} onClick={openViewPage}>
                              <i className={subitem.icon}></i>
                              <p>{subitem.title}</p>
                            </span>
                          </Link>
                        </Card.Body>
                      </Accordion.Collapse>
                    ))
                  )}
                </Card>
              </Accordion>
            ) : (
              <div key={generateHash()}></div>
            )
          )}
        </Nav>
      </div>
    </div>
  );
};
export default Sidebar;
