import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Actions from "../actions/form-actions";
import Table from "react-bootstrap/Table";
import "./stylesheet/users.css";
import Form from "react-bootstrap/Form";
const Users = () => {
  const tableheader = [
    { header: "S:NO" },
    { header: "Name" },
    { header: "Email" },
    { header: "phoneNumber" },
    { header: "Address" },
    { header: "City" },
  ];

  const [alert] = useState(false);
  const [alertMsg] = useState("");
  const [usersList, setUsersLists] = useState([]);

  useEffect(() => {
    try {
      Actions.data("users", function (documents) {
        setUsersLists(documents);
      });
    } catch (error) {}
  }, []);
  const filterByName = (e) => {};

  const FormFeedback = (props) => {
    return (
      <Form.Control.Feedback type="invalid">
        {props.message}
      </Form.Control.Feedback>
    );
  };

  return (
    <>
      {alert ? (
        <div
          style={{ backgroundColor: "#e14eca", width: "50%" }}
          className="alert alert-danger d-flex align-self-center justify-content-center  "
          role="alert"
        >
          <strong>{alertMsg} </strong>
        </div>
      ) : (
        ""
      )}

      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Card.Title className="card-title">
                <h2> Users </h2>
                {/* filter Area started */}
                {/* Filling Searchable dropdown with users */}

                <Form.Group className="mt-3" controlId="searchbyDoc">
                  <span className="mr-4 font-small  ">Search By User Name</span>
                  <Form.Control
                    onChange={filterByName}
                    list="userslist"
                    name="userslist"
                    className="form-control"
                    style={{
                      width: "29%",
                      display: "unset",
                      marginLeft: "61px",
                    }}
                  />
                  <datalist id="userslist">
                    {usersList.map((singleUser) => (
                      <option key={Math.random()} value={singleUser.name} />
                    ))}
                  </datalist>
                </Form.Group>

                {/* Filling Searchable dropdown with usersList

              {/* filter Area ended */}
              </Card.Title>
              <div className="table-responsive">
                <Table className="table tablesorter " id="">
                  <thead className=" text-primary">
                    <tr>
                      {tableheader.map((item) => (
                        <th className="text-center" key={Math.random()}>
                          {item.header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {usersList.map((item, index) => (
                      <tr key={Math.random()}>
                        <td className="text-center">{index}</td>
                        <td className="text-center">{item.firstName} {item.lastName}</td>
                        <td className="text-center">{item.email}</td>
                        <td className="text-center">{item.mobileNo ?? item.phoneNumber}</td>
                        <td className="text-center">{item.address}</td>
                        <td className="text-center">{item.city}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Users;
