import React, { useContext, useState } from "react";
import Context from "../context/Context";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { firebaseAuth } from "../provider/authprovider";

/* 
                   * @Navbar contains the search dialoug, profile menu, setting dropdown
                   * @pageTitle conains title text from state
                   * this jsx section creates Navbar using using components @Nav
                   * @lgShow use for search Dialouge 
                   
                 */

const Navbar = () => {
  const { pageTitle } = useContext(Context);
  const { handleSignout } = useContext(firebaseAuth);
  const [lgShow, setLgShow] = useState(false);
  const handlelogout = () => {
    handleSignout();
  };
  return (
    <>
      {/* <!-- Navbar --> */}
      <Nav
        className="navbar navbar-expand-lg navbar-absolute navbar-transparent _nav"
        style={{ paddingLeft: "248px", width: "100%" }}
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle d-inline">
              <Button className="navbar-toggler">
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </Button>{" "}
            </div>
            <p className="navbar-brand">{pageTitle}</p>
          </div>
          <Button
            className="navbar-toggler "
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
          </Button>{" "}
          <div className="collapse navbar-collapse " id="navigation">
            <ul className="navbar-nav ml-auto">
              {/* <li className="search-bar input-group">
                            
                                <Button className="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal" onClick={() => setLgShow(true)}><i className="tim-icons icon-zoom-split"></i>
                                    <span className="d-lg-none d-md-block">Search</span></Button>{' '}
                            </li> */}
              {/* 
                            <li className="dropdown nav-item" >
                                <Dropdown>
                                    <Dropdown.Toggle className="_nav_notifications" >
                                        <Link to="/" className="dropdown-toggle nav-link _nav_notifications" data-toggle="dropdown">
                                            <div className="notification d-none d-lg-block d-xl-block"></div>
                                            <i className="tim-icons icon-sound-wave"></i>
                                            <span className="d-lg-none">
                                                Notifications
                                                      </span>
                                        </Link>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item >Mike John responded to your email</Dropdown.Item>
                                        <Dropdown.Item >You have 5 more tasks</Dropdown.Item>
                                        <Dropdown.Item >Your friend Michael is in town</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li > */}

              <li className="separator d-lg-none"></li>

              <li className="dropdown nav-item">
                <Dropdown>
                  <Dropdown.Toggle className="_nav_menu">
                    <Link
                      to="/"
                      className="dropdown-toggle nav-link"
                      data-toggle="dropdown"
                    >
                      <div className="photo">
                        <img src="../assets/img/anime3.png" alt="" />
                      </div>
                      <b className="caret d-none d-lg-block d-xl-block"></b>
                    </Link>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item >  <Link to={"/specialRequest"} style={{ color: "#9A9A9A" }} > Special Requests  </Link></Dropdown.Item>

                                        <Dropdown.Item >  <Link to={"/createSubAdmin"} style={{ color: "#9A9A9A" }} > Sub Admins</Link></Dropdown.Item> */}
                    <Dropdown.Item onClick={handlelogout}>
                      {" "}
                      <Link to={"/"} style={{ color: "#9A9A9A" }}>
                        {" "}
                        Signout{" "}
                      </Link>{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </Container>
      </Nav>

      <div
        className="modal modal-search fade"
        role="dialog"
        aria-labelledby="searchModal"
        aria-hidden="true"
      >
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          variant="secondary"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              style={{ color: "black" }}
              type="text"
              className="form-control "
              placeholder="SEARCH"
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Navbar;
