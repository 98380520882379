import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  ProgressBar,
} from "react-bootstrap";
import { EditType } from "../../../../config/forms/form";
import sendMessage from "../../../../dao/firebase/fcm";
import { Firestore } from "../../../../dao/firebase/firebase-firestore";
import generateHash from "../../../../helpers/hash";
import Validate from "../../../../validation/validate";
import Dialog from "../../../dialog/dialog";
import Flex, { FlexProps } from "../../../flex-box/flex";

const FormFeedback = (props) => {
  return (
    <Form.Control.Feedback type="invalid">
      {props.message}
    </Form.Control.Feedback>
  );
};
class CampaignForm extends Component {
  initState() {
    this.state.campaign =
      this.props.editType === "edit"
        ? this.props.data
        : {
            prize: {
              description: "",
              image: "",
              title: "",
            },
            product: {
              description: "",
              image: "",
              price: "",
              title: "",
            },
            soldCount: "",
            threshold: "",
            title: "",
            status: "",
            id: "",
            flash: false,
          };
  }
  constructor(props) {
    super(props);
    this.state = {
      notify: false,
      tokens: [],
      editType: this.props.editType,
      campaign:
        this.props.editType === "edit"
          ? this.props.data
          : {
              prize: {
                description: "",
                image: "",
                title: "",
              },
              product: {
                description: "",
                image: "",
                price: "",
                title: "",
              },
              soldCount: "",
              threshold: "",
              title: "",
              status: "",
              id: "",
              flash: false,
            },
      validated: false,
      showDialog: false,
      fileProgress: 0,
    };

    this.setFormData = this.setFormData.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }
  // const [campaignData, setCampaignData] = useState(CampaignModel);
  // const [validated, setValidated] = useState(false);
  // setCampaignData(props.data);

  uploadFile(event) {
    this.setState({ showDialog: true });

    var file = event.target.files[0];
    const name = event.target.name;

    Firestore.upload(
      "campaigns",
      file,
      (snapshot) => {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        if (this.state.fileProgress < 100) {
          this.setState({ fileProgress: percentage });
        }
      },
      (error) => {
        this.setState({ showDialog: false });
      },
      (fileURL) => {
        const tempCampaign = this.state.campaign;
        if (name === "product.image") {
          tempCampaign.product.image = fileURL;
          this.setState({ campaign: tempCampaign });
        } else if (name === "prize.image") {
          tempCampaign.prize.image = fileURL;
          this.setState({ campaign: tempCampaign });
        }
        this.setState({ showDialog: false });
        this.setState({ fileProgress: 0 });
      }
    );
  }

  setFormData(event) {
    const tempData = this.state.campaign;
    const name = event.target.name;
    if (name.indexOf(".") > 0) {
      const nameArr = name.split(".");
      tempData[nameArr[0]][nameArr[1]] = event.target.value;
      this.setState({ campaign: tempData });
      return;
    }
    if (event.target.type === "checkbox") {
      tempData[name] = event.target.checked;
    } else {
      tempData[event.target.name] = event.target.value;
    }
    this.setState({ campaign: tempData });
  }

  // const temp = CampaignActions.setFormData(e, campaignData);
  // setCampaignData(temp);

  // ABOVE methods contain bugs

  onSubmit = async (e) => {
    e.preventDefault();
    const validateStatus = Validate.form(e);
    console.log(
      "this is new file here &&&&&&& ",
      JSON.stringify(this.state.campaign)
    );
    this.props.afterSubmit(
      this.state.campaign,
      validateStatus,
      this.state.editType
    );
    if (this.state.notify) {
      if (this.state.tokens.length === 0) {
        const { id, data } = await Firestore.documentAsync(
          "tokens",
          "all_tokens"
        );
        this.setState({
          tokens: Object.values(data),
        });
        sendMessage(
          this.state.campaign.title,
          this.state.campaign.prize.title,
          this.state.campaign.prize.image,
          Object.values(data)
        );
      } else {
        sendMessage(
          this.state.campaign.title,
          this.state.campaign.prize.title,
          this.state.campaign.prize.image,
          this.state.tokens
        );
      }
    }
    this.setState({ validated: true });
    // e.target.reset();
    // CampaignActions.submitForm(e, this.state.campaign);
  };

  FormField = (
    props = {
      as: Col,
      md: 4,
      label: "",
      type: "",
      name: "",
      placeholder: "",
      value: "",
      onChange: () => {},
      required: true,
      validateMessage: "",
    }
  ) => {
    return (
      <Form.Group
        controlId={generateHash()}
        key={generateHash()}
        as={props.as}
        md={props.md}
      >
        <Form.Label className={""}>{props.label}</Form.Label>
        {props.required ? (
          <Form.Control
            onChange={props.onChange}
            value={props.value}
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
            required
          />
        ) : (
          <Form.Control
            onChange={props.onChange}
            value={props.value}
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
          />
        )}
        <FormFeedback message={props.validateMessage} />
      </Form.Group>
    );
  };

  render() {
    return (
      <>
        {this.state.showDialog ? (
          <Dialog title={"Image Uploading"} show={this.state.showDialog}>
            <Flex
              justifyContent={FlexProps.justifyContent.center}
              alignItems={FlexProps.alignItems.center}
            >
              <ProgressBar
                variant="success"
                min={0}
                max={100}
                now={this.state.fileProgress}
              />
            </Flex>
          </Dialog>
        ) : (
          <></>
        )}

        <Card.Title className="d-flex justify-content-between">
          <h2 className={""}> Create campaign </h2>
          {alert ? <Alert message={"Test Alert"} /> : <div></div>}
        </Card.Title>
        <>
          <Form.Check
            checked={this.state.notify}
            type="switch"
            id="notify"
            style={{ fontSize: "24px" }}
            label="Notify users"
            onClick={(e) => {
              console.log(`e.target.checked`, e.target.checked);
              this.setState({
                notify: e.target.checked,
              });
            }}
            name={"notify"}
          />
          <br />
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.onSubmit}
          >
            {/* gernal info */}

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className={""}>Campaign Title</Form.Label>

                <Form.Control
                  value={this.state.campaign.title}
                  onChange={this.setFormData}
                  type={"text"}
                  name={"title"}
                  placeholder={"Enter campaign Title"}
                />
                <FormFeedback
                  message={"Please provide a valid campaign title."}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className={""}>Campaign Threshold</Form.Label>
                <Form.Control
                  onChange={this.setFormData}
                  value={this.state.campaign.threshold}
                  type={"number"}
                  name={"threshold"}
                  placeholder={"Enter Threshold"}
                  required
                />
                <FormFeedback
                  message={"Please provide a valid campaign threshold."}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className={""}>{"Select Status"}</Form.Label>
                <Form.Control
                  value={this.state.campaign.status}
                  onChange={this.setFormData}
                  as={"select"}
                  name={"status"}
                  placeholder={"Select Status"}
                  required
                >
                  <option
                    key={generateHash()}
                    className={"darkbg"}
                    value={"active"}
                  >
                    {"Active"}
                  </option>
                  <option
                    key={generateHash()}
                    className={"darkbg"}
                    value={"inactive"}
                  >
                    {"In Active"}
                  </option>
                  {this.state.campaign.soldCount > 3 ? (
                    <>
                      <option
                        key={generateHash()}
                        className={"darkbg"}
                        value={"completed"}
                      >
                        {"Complete"}
                      </option>
                      <option
                        key={generateHash()}
                        className={"darkbg"}
                        value={"finished"}
                      >
                        {"Finished"}
                      </option>
                    </>
                  ) : (
                    ""
                  )}
                </Form.Control>
                <FormFeedback
                  message={"Please provide a valid campaign status."}
                />
              </Form.Group>
            </Form.Row>
            <Row>
              <h4 className="pl-3 pt-3 "> {"Product Details"}</h4>
            </Row>
            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className={""}>Product Title</Form.Label>
                <Form.Control
                  value={this.state.campaign.product.title}
                  onChange={this.setFormData}
                  type={"text"}
                  name={"product.title"}
                  placeholder={"Enter Product Title"}
                  required
                />
                <FormFeedback
                  message={"Please provide a valid product title."}
                />
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <Form.Label className={""}>Product Price</Form.Label>
                <Form.Control
                  value={this.state.campaign.product.price}
                  onChange={this.setFormData}
                  type={"number"}
                  name={"product.price"}
                  placeholder={"Enter Product Price"}
                  required
                />
                <FormFeedback
                  message={"Please provide a valid product price."}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className={"fileUploadLabel"}>
                  Product Image
                </Form.Label>
                <Form.Control
                  onChange={this.uploadFile}
                  type={"file"}
                  accept={".gif,.jpg,.jpeg,.png"}
                  name={"product.image"}
                />

                <FormFeedback
                  message={"Please provide a valid product image."}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className={""}>Product Description</Form.Label>
                <Form.Control
                  value={this.state.campaign.product.description}
                  onChange={this.setFormData}
                  as={"textarea"}
                  name={"product.description"}
                  placeholder={"Enter Product Description"}
                  required
                />

                <FormFeedback
                  message={"Please provide a valid product description."}
                />
              </Form.Group>
            </Form.Row>

            <Row>
              <h4 className="pl-3 pt-3 "> {"Reward Details"}</h4>
            </Row>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className={""}>Reward Title</Form.Label>
                <Form.Control
                  value={this.state.campaign.prize.title}
                  onChange={this.setFormData}
                  type={"text"}
                  name={"prize.title"}
                  placeholder={"Enter Reward Title"}
                  required
                />
                <FormFeedback
                  message={"Please provide a valid reward title."}
                />
              </Form.Group>
              <Form.Group className="mt-4" as={Col} md={2}>
                {this.state.campaign.flash ? (
                  <Form.Check
                    checked
                    type="switch"
                    id="flashDeal"
                    label="Flash Deal "
                    onClick={this.setFormData}
                    name={"flash"}
                  />
                ) : (
                  <Form.Check
                    unChecked
                    type="switch"
                    id="flashDeal"
                    label="Flash Deal "
                    onClick={this.setFormData}
                    name={"flash"}
                  />
                )}
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <Form.Label className={"fileUploadLabel"}>
                  Reward Image
                </Form.Label>
                <Form.Control
                  onChange={this.uploadFile}
                  type={"file"}
                  accept={".gif,.jpg,.jpeg,.png"}
                  name={"prize.image"}
                />
                <FormFeedback
                  message={"Please provide a valid reward image."}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className={""}>Reward Description</Form.Label>
                <Form.Control
                  value={this.state.campaign.prize.description}
                  onChange={this.setFormData}
                  as={"textarea"}
                  name={"prize.description"}
                  placeholder={"Enter Reward Description"}
                  required
                />
                <FormFeedback
                  message={"Please provide a valid reward description."}
                />
              </Form.Group>
            </Form.Row>

            <Button type="submit">
              {this.state.editType === EditType.create ? "Save" : "Update"}
            </Button>
          </Form>
        </>
      </>
    );
  }
}

export default CampaignForm;
