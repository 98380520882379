import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Actions from "../actions/form-actions";
import "./stylesheet/campaign.css";
import Form from "react-bootstrap/Form";
import TrasantionModel from "../../../models/transaction-model";
import { Firestore } from "../../../dao/firebase/firebase-firestore";
import TransactionActions from "./actions/transaction-actions";

const Transaction = () => {
  const tableheader = [
    { header: "S:NO" },
    { header: "TransactionID" },
    { header: "Payment Method" },
    { header: "Campaign" },
    { header: "Delivery Address" },
    { header: "Date" },
    { header: "Status" },
    { header: "Actions" },
  ];
  const [showEditPopUp, setshowEditPopUp] = useState(false);
  const [status, setStatus] = useState("paid");
  const [searchedTrxID, setSearchedTrxID] = useState("");
  const [searchedCampaignName, setSearchedCampaignName] = useState("");

  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [showDetailPopUp, setshowDetailPopUp] = useState(false);
  const [transactionModel, setTransactionModel] = useState({ TrasantionModel });
  const [selectedTransactionForUpdate, setSelectedTransactionForUpdate] =
    useState(null);
  const [rawData, setRawData] = useState({});
  const [singleCompaignTransaction, setSingleCompaignTransaction] = useState(
    {}
  );
  const [alert, setalert] = useState(false);
  const [alertMsg, setalertMsg] = useState("");
  const [orderId, setOrderId] = useState("");
  const [userId, setUserId] = useState("");
  const [grandTotal, setGrandTotal] = useState("");

  const [searchedDate, setSearchDate] = useState("");

  const filterByTrxID = (e) => {
  
    setSearchedTrxID(e.target.value);
    console.log("values here  ", e.target.value);
  };

  const filterByCampaignName = (e) => {
    setSearchedCampaignName(e.target.value);
  };
  const filterByDate = (e) => {
    console.log(new Date(e.target.value).toDateString());
    setSearchDate(new Date(e.target.value).toDateString());
  };

  const filterTransactions = () => {
    setFilteredTransactions([]);
    const ftrx = [];
    setTimeout(() => {
      transactions.forEach((item, i) => {
        console.log(
          `item date`,
          new Date(item.date).toDateString(),
          "search date",
          searchedDate
        );
        const orderID = item.orderId;
        const campaigns = item.campaigns;
        var titleEquals = false;
        Object.keys(campaigns).forEach((campaignID) => {
          titleEquals =
            campaigns[campaignID].title.toLowerCase() ===
            searchedCampaignName.toLowerCase();
          if (titleEquals) {
            console.log("title equals", titleEquals);
            ftrx.push(item);
          }
          titleEquals = false;
        });
        if (orderID === searchedTrxID) {
          console.log(`trx filter`, orderID, searchedTrxID);
          ftrx.push(item);
        } else if (
          new Date(item.date).toDateString().indexOf(searchedDate) > -1 &&
          titleEquals
        ) {
          console.log(`date title filter`);
          ftrx.push(item);
        } else if (
          searchedDate !== "" &&
          new Date(item.date).toDateString().indexOf(searchedDate) > -1
        ) {
          console.log(`datefilter`);
          ftrx.push(item);
        }
      });
      setFilteredTransactions(ftrx);
      setSearchDate("");
      setSearchedTrxID("");
      setSearchedCampaignName("");
    }, 1000);
  };
  const getTransactionsCampaignSet = () => {
    var campaignTitleSet = [];
    // ...new Set(array)
    transactions.forEach((item) => {
      Object.keys(item.campaigns).map((campaignID) => {
        if (!campaignTitleSet.includes(item.campaigns[campaignID].title)) {
          campaignTitleSet.push(item.campaigns[campaignID].title);
        }
      });
    });
    return campaignTitleSet;
  };
  const createTableView = (docs) => {
    const view = [];
    docs.forEach((doc) => {
      const singleUserTransactions = doc.data();
      var userId = doc.id;
      for (const orderId in singleUserTransactions) {
        var obj = {};
        obj = singleUserTransactions[orderId];
        obj["orderId"] = orderId;
        obj["userId"] = userId;
        view.push(obj);
      }
    });

    var sortedView = view.sort(function (a, b) {
      var c = new Date(a.date);
      var d = new Date(b.date);
      return d - c;
    });

    console.log(`transactions`, JSON.stringify(sortedView));
    setTransactions(sortedView);
    setFilteredTransactions(sortedView);
  };
  useEffect(() => {
    Firestore.allDocs("userTransactions", function (documents) {
      setRawData(documents);
      createTableView(documents);
      console.info({ message: "use effect is called" });
    });
    Firestore.allDocs("campaignTransactions", function (documents) {
      setSingleCompaignTransaction(documents);
    });
    console.log(`filterTransactions`, JSON.stringify(filterTransactions));
  }, []);

  const handleChange = (e) => {
    setTransactionModel({
      ...transactionModel,
      [e.target.name]: e.target.value,
    });
  };
  const handleSave = (e) => {
    try {
      if (Actions.validate(transactionModel.status)) {
        // update view
        var tempTransactions = transactions;
        tempTransactions.forEach((doc) => {
          if (doc.orderId === orderId && doc.userId === userId) {
            doc.status = transactionModel.status;
          }
        });
        console.log(`transactionModel`, selectedTransactionForUpdate);

        // if (transactionModel.status === "unPaid") {
        //   const filteredCampaigns = [];
        //   transactionModel.campaigns.forEach((campaign) => {
        //     const singleCampaign = campaign;
        //     delete singleCampaign["product"]["coupons"];
        //     delete singleCampaign["count"];
        //     delete singleCampaign["grandTotal"];

        //     filteredCampaigns.push(singleCampaign);
        //   });

        //   if (filteredCampaigns.length > 0) {
        //     filteredCampaigns.forEach((singleCampaign) => {
        //       if (singleCampaign.status === "completed") {
        //         singleCampaign["status"] = "active";
        //         Actions.saveWithId(
        //           "campaigns",
        //           singleCampaign.id,
        //           singleCampaign
        //         );
        //       } else {
        //         Actions.saveWithId(
        //           "campaigns",
        //           singleCampaign.id,
        //           singleCampaign
        //         );
        //       }
        //       Firestore.document(
        //         "coupons",
        //         singleCampaign.id,
        //         function (singleCamaignCoupons) {
        //           if (
        //             singleCamaignCoupons.data[userId] !== null ||
        //             singleCamaignCoupons.data[userId] !== undefined
        //           ) {
        //             singleCamaignCoupons.data[userId]["coupons"] = [];
        //             Firestore.saveWithId(
        //               "coupons",
        //               singleCampaign.id,
        //               singleCamaignCoupons.data
        //             );
        //           }
        //         }
        //       );
        //       Firestore.document(
        //         "coupons",
        //         singleCampaign.id,
        //         function (singleCamaignCoupons) {
        //           if (
        //             singleCamaignCoupons.data[userId] !== null ||
        //             singleCamaignCoupons.data[userId] !== undefined
        //           ) {
        //             singleCamaignCoupons.data[userId]["coupons"] = [];
        //             Firestore.saveWithId(
        //               "coupons",
        //               singleCampaign.id,
        //               singleCamaignCoupons.data
        //             );
        //           }
        //         }
        //       );
        //     });
        //   }
        // }
        setTransactions(tempTransactions);
        console.log(`tempTransactions`, tempTransactions);
        // update view ended
        // updaing 2nd Table CompaignsTransations
        TransactionActions.filterSingleCampaignTransactions(
          singleCompaignTransaction,
          userId,
          transactionModel,
          orderId,
          function (
            tempsingleCampaignTransactions,
            docId,
            userId,
            transactionModel,
            orderId
          ) {
            var campaignTransactionCollection = tempsingleCampaignTransactions;
            campaignTransactionCollection[orderId] = {
              description: transactionModel.description,
              status: transactionModel.status,
              orderId: orderId,
              userId: userId,
              grandTotal: transactionModel.grandTotal,
            };
            Actions.saveWithId(
              "campaignTransactions",
              docId,
              campaignTransactionCollection
            );
          }
        );
        // updaing ist Table userTransactions
        var collection = TransactionActions.filterSingleUserTransactions(
          rawData,
          userId,
          orderId
        );

        const temp = {
          campaigns: {},
          description: transactionModel.description,
          grandTotal: transactionModel.grandTotal,
          status: transactionModel.status,
          transctionId: orderId,
        };

        transactionModel.campaigns.forEach((campaign) => {
          temp.campaigns[campaign.id] = campaign;
        });

        collection[orderId] = temp;
        //  ustaad
        //   // console.log("data &&&  1", JSON.stringify(transactionModel ))
        //   var tempmodal = transactionModel;
        //   // console.log("data &&&  2", JSON.stringify(transactionModel.campaigns))
        //   // collection[orderId] = transactionModel.campaigns;
        //  collection[orderId] = transactionModel
        //  collection[orderId]['ccc'] = transactionModel['campaigns'][0]
        //  collection["status"] = transactionModel.status

        //   console.log("data &&&  1", JSON.stringify(transactionModel ))
        //   var tempmodal = transactionModel;
        //   console.log("data &&&  2", JSON.stringify(transactionModel.campaigns))
        //   tempmodal["campaigns"][transactionModel.campaigns[0].id] =  transactionModel.campaigns;
        //   collection[orderId] = tempmodal;
        // console.log("data &&& 3", JSON.stringify(collection))

        // Actions.saveWithId("pending", orderId, collection);
        Actions.showAlert(
          "Information has been updated",
          setalertMsg,
          setalert,
          "2000"
        );
        setshowEditPopUp(false);
      } else {
        Actions.showAlert("Select Status", setalertMsg, setalert, "2000");
      }
    } catch (error) {}
  };

  const genrateArray = (campaigns) => {
    const tempArra = [];
    for (var campaignId in campaigns) {
      tempArra.push(campaigns[campaignId]);
    }
    return tempArra;
  };

  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Body>
            <Card.Title className="card-title">
              <h2> Transactions </h2>

              {alert ? (
                <span
                  style={{ backgroundColor: "#e14eca", width: "50%" }}
                  className="alert alert-danger  ml-5 align-self-center justify-content-center  "
                  role="alert"
                >
                  <strong>{alertMsg} </strong>
                </span>
              ) : (
                ""
              )}
              {/* Filling Searchable dropdown with doctors */}

              <Form.Row>
                <Form.Group
                  className="mt-3 flex flex-column"
                  controlId="searchbyDoc"
                  as={Col}
                  md={4}
                >
                  <Form.Control
                    onChange={filterByTrxID}
                    list="transactions"
                    name="transactions"
                    className="form-control"
                    placeholder={"Search By Transaction ID"}
                  />
                  <datalist id="transactions">
                    {transactions.map((transaction) => (
                      <option key={Math.random()} value={transaction.orderId} />
                    ))}
                  </datalist>
                </Form.Group>

                <Form.Group
                  className="mt-3 flex flex-column"
                  controlId="searchbyDoc"
                  as={Col}
                  md={4}
                >
                  <Form.Control
                    onChange={filterByDate}
                    className="form-control"
                    type="date"
                    placeholder={"Search By Date"}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md={4}
                  className="mt-3"
                  controlId="searchbyDoc"
                >
                  <Form.Control
                    onChange={filterByCampaignName}
                    list="transactionCampaigns"
                    name="transactionCampaigns"
                    className="form-control"
                    placeholder={"Search By Campaign"}
                  />

                  <datalist id="transactionCampaigns">
                    {getTransactionsCampaignSet().map((title) => {
                      return <option key={Math.random()} value={title} />;
                    })}
                  </datalist>

                  {/* <datalist id="transactions">
                 
                </datalist> */}
                </Form.Group>
              </Form.Row>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <span className={"m-4"}></span>
                <Button onClick={filterTransactions}>Filter</Button>
                <span className={"m-4"}></span>
                <Button
                  onClick={() => {
                    setFilteredTransactions(transactions);
                  }}
                >
                  Clear
                </Button>
              </div>
              {/* Filling Searchable dropdown with doctors  ended

              {/* filter Area ended */}
            </Card.Title>
            <div className="table-responsive">
              <Table className="table tablesorter " id="">
                <thead className=" text-primary">
                  <tr>
                    {tableheader.map((item) => (
                      <th className="text-center" key={Math.random()}>
                        {item.header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredTransactions
                    .filter(
                      (item) =>
                        item.orderId === searchedTrxID ||
                        new Date(item.date)
                          .toDateString()
                          .toLowerCase()
                          .indexOf(searchedDate) > -1
                    )
                    .map((item, index) => (
                      <tr key={Math.random()}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{item.orderId}</td>
                        <td className="text-center">{item.checkoutType}</td>
                        <td className="text-center">
                          <ul>
                            {Object.keys(item.campaigns).map((campaign) => (
                              <li
                                key={Math.random()}
                                style={{ padding: "12px" }}
                              >
                                {item.campaigns[campaign].title}
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td className="text-center">{item.address}</td>
                        <td className="text-center">
                          {new Date(item.date).toDateString()}
                        </td>
                        <td className="text-center">{item.status}</td>

                        <td className="text-center">
                          <Button
                            onClick={() => {
                              window.scrollTo(0, 0);
                              setshowEditPopUp(true);
                              setOrderId(item.orderId);
                              setUserId(item.userId);
                              setSelectedTransactionForUpdate(item);
                              setTransactionModel({
                                description: item.description,
                                status: item.status,
                                campaigns: genrateArray(item.campaigns),
                                userId: item.userId,
                                grandTotal: item.grandTotal,
                              });
                            }}
                            className="btn btn-icon btn-round  "
                          >
                            <i className="tim-icons icon-pencil"></i>
                          </Button>
                          <Button
                            onClick={() => {
                              setshowDetailPopUp(true);
                              setGrandTotal(item.grandTotal);
                              setTransactionModel({
                                description: item.description,
                                status: item.status,
                                campaigns: genrateArray(item.campaigns),
                                userId: item.userId,
                                grandTotal: item.grandTotal,
                              });
                            }}
                            className="btn btn-icon btn-round  "
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Col>

      {/* Edit pop started  */}
      <Modal
        size="md"
        show={showEditPopUp}
        onHide={() => setshowEditPopUp(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        variant="warning"
      >
        <Modal.Header className="darkbg" closeButton>
          <Modal.Title
            className="white-color  font-large"
            id="example-modal-sizes-title-lg"
          >
            <p className="white-color  font-large">Approve Payment </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-item-center flex-column darkbg">
          <div className="d-flex justify-content-center align-item-center">
            <div className="d-flex flex-column">
              <Form.Check type={"radio"} id={"check-api-radio"}>
                <Form.Check.Input
                  name="status"
                  onChange={handleChange}
                  value={"paid"}
                  type={"radio"}
                  isValid
                />
                <Form.Check.Label className="font-medium">
                  {"Paid"}
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type={"radio"} id={"check-api-radio"}>
                <Form.Check.Input
                  name="status"
                  onChange={(e) => {
                    handleChange(e);
                    console.log(`userId, orderId`, userId, orderId);
                    // Firestore.delete("userTransactions", userId);

                    Firestore.deleteKey("userTransactions", userId, orderId);
                    Firestore.update("pending", orderId, { payment: "unpaid" });

                    var tempFilteredTransactions = filteredTransactions.filter(
                      function (item) {
                        return item.orderId !== orderId;
                      }
                    );
                    setFilteredTransactions(tempFilteredTransactions);
                  }}
                  value={"unPaid"}
                  type={"radio"}
                  isValid
                />
                <Form.Check.Label className="font-medium">
                  {"UnPaid"}
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="d-flex mt-3 justify-content-center align-item-center flex-row">
            <Button
              type="submit"
              onClick={handleSave}
              className="btn btn-icon btn-round medium-button "
            >
              Update..
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete PopUp */}

      <Modal
        size="xl"
        show={showDetailPopUp}
        onHide={() => setshowDetailPopUp(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        variant="warning"
      >
        <Modal.Header className="darkbg" closeButton>
          <Modal.Title
            className="white-color  font-large"
            id="example-modal-sizes-title-lg"
          >
            Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-item-center flex-column darkbg">
          <div className="table-responsive">
            <Table className="table tablesorter " id="details">
              <thead className=" text-primary">
                <tr>
                  <th className="text-center">Campaign</th>
                  <th className="text-center">Product</th>
                  <th className="text-center">prize</th>
                  <th className="text-center">Purchase</th>
                  <th className="text-center">Price</th>
                  <th className="text-center">Coupons</th>
                  <th className="text-center">
                    Grand Total ( {parseFloat(grandTotal).toFixed(2)} )
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactionModel?.campaigns?.map((campaign) => (
                  <tr key={Math.random()}>
                    <td className="text-center">{campaign.title}</td>
                    <td className="text-center">{campaign.product.title}</td>
                    <td className="text-center">{campaign.prize.title}</td>
                    <td className="text-center">{campaign.count}</td>
                    <td className="text-center">{campaign.product.price}</td>
                    <td className="text-center">
                      {campaign?.product?.coupons?.map((coupon) => (
                        <span
                          key={Math.random()}
                          className="badge badge-danger ml-1"
                        >
                          {coupon}
                        </span>
                      ))}
                    </td>
                    <td className="text-center">
                      {(
                        parseFloat(campaign.product.price) * campaign.count
                      ).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex x justify-content-center align-item-center flex-row">
            <Button
              type="submit"
              className="btn btn-icon btn-round  medium-button "
              onClick={() => setshowDetailPopUp(false)}
            >
              {" "}
              Cancel
            </Button>{" "}
          </div>
        </Modal.Body>
      </Modal>
    </Row>
  );
};

export default Transaction;
