import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import "./stylesheet/campaign.css";
import Form from "react-bootstrap/Form";
import { Firestore } from "../../../dao/firebase/firebase-firestore";
import { Button } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";
const PendingTransaction = () => {
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [showDetailPopUp, setshowDetailPopUp] = useState(false);

  const [grandTotal, setGrandTotal] = useState("");
  const [selectedCheckoutType, setSelectedCheckoutType] = useState("");
  const tableheader = [
    { header: "S:NO" },
    { header: "TransactionID" },
    { header: "Campaign" },
    { header: "Payment Method" },
    { header: "Delivery Address" },
    { header: "Date purchased" },
    { header: "Contact No" },
    { header: "Status" },
    { header: "Actions" },
  ];
  const [transactionsData, setTransactions] = useState([]);
  const [pendingTransactions, setPendingTransactions] = useState([]);

  const [status, setStatus] = useState("unpaid");
  const filterByTrxID = (e) => {
    var tempFiltered = pendingTransactions.filter(
      (item) => item["Id"] === e.target.value
    );
    console.log(`tempFiltered`, tempFiltered);
    if (tempFiltered.length > 0) {
      setTransactions(tempFiltered);
    } else {
      setTransactions(pendingTransactions);
    }
    // setSearchedTrxID(e.target.value);
    console.log("values here  ", e.target.value);
  };
  const filterByTrxName = (e) => {
    var tempTransactions = [];
    if (e.target.value !== "") {
      pendingTransactions.forEach((v, i) => {
        var data = v["data"]["dataAfterVerify"];
        data.forEach((c, ci) => {
          if (c["collectionName"] === "userTransactions") {
            var doc = c["doc"];

            for (const trxID in doc) {
              if (Object.hasOwnProperty.call(doc, trxID)) {
                const transaction = doc[trxID];
                const campaigns = transaction["campaigns"];
                for (const campaignId in campaigns) {
                  if (Object.hasOwnProperty.call(campaigns, campaignId)) {
                    const campaign = campaigns[campaignId];

                    if (
                      campaign.title
                        .toLocaleLowerCase()
                        .indexOf(e.target.value.toLocaleLowerCase()) > -1
                    ) {
                      console.log(`campaign`, campaign);
                      tempTransactions.push(v);
                    }
                  }
                }
              }
            }
          }
        });
      });
      console.log(`tempTransactions`, tempTransactions);
      if (tempTransactions.length > 0) {
        setTransactions(tempTransactions);
      } else {
        setTransactions(pendingTransactions);
      }
      console.log("values here  ", e.target.value);
    } else {
      setTransactions(pendingTransactions);
    }
  };
  const getCampaignTitles = (data) => {
    return Object.keys(data).map((key, index) => {
      const transaction = data[key];
      return (
        <span key={Math.random()}>
          <b>{transaction.title}</b> ,
        </span>
      );
    });
  };
  useEffect(() => {
    Firestore.dataWithMultipleCondition(
      "pending",
      { field: "method", filter: "==", value: "other" },
      { field: "payment", filter: "==", value: "unpaid" },
      function (soldCampaigns) {
        console.log(
          "pending transaction data line 46",
          soldCampaigns,
          typeof soldCampaigns
        );
        setPendingTransactions(soldCampaigns);
        setTransactions(soldCampaigns);
      }
    );
  }, []);
  const generateTableRows = (data) => {
    console.log(`data`, data);
    const campaigns = [];
    for (var id in data) {
      campaigns.push(data[id]);
    }
    return campaigns;
  };
  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Body>
            <Card.Title className="card-title">
              <h2> Transactions </h2>
              {/* filter Area started */}

              <Form.Row>
                <Form.Group
                  className="mt-3 flex flex-column"
                  controlId="searchbyDoc"
                  as={Col}
                  md={4}
                >
                  <Form.Control
                    onChange={filterByTrxID}
                    list="transactions"
                    name="transactions"
                    className="form-control"
                    placeholder={"Search By Transaction ID"}
                  />
                  <datalist id="transactions">
                    {pendingTransactions.map((item) => {
                      console.log(`item`, item);
                      return <option key={Math.random()} value={item["Id"]} />;
                    })}
                  </datalist>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md={4}
                  className="mt-3"
                  controlId="searchbyDoc"
                >
                  <Form.Control
                    onChange={filterByTrxName}
                    list="transactionCampaigns"
                    name="transactionCampaigns"
                    className="form-control"
                    placeholder={"Search By Campaign"}
                  />

                  <datalist id="transactionCampaigns"></datalist>
                </Form.Group>
              </Form.Row>
            </Card.Title>
            <div className="table-responsive">
              <Table className="table tablesorter " id="">
                <thead className=" text-primary">
                  <tr>
                    {tableheader.map((item) => (
                      <th className="text-center" key={Math.random()}>
                        {item.header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {transactionsData.map((item, index) => {
                    // return <p>${item["data"]}</p>;
                    const orderID = item["Id"];

                    const _data = item["data"]["dataAfterVerify"];

                    var data = [];
                    if (typeof _data === "string") {
                      data = JSON.parse(_data);
                    } else {
                      data = _data;
                    }
                
                    console.log(`data runtime type`, typeof data);
                    const checkoutType = item["data"]["checkoutType"];
                    const fullData = item["data"];
                    var metaData = {};
                    var transactionDetails = {};
                    {
                      data.map((collection, index) => {
                        if (
                          collection["collectionName"] ===
                          "campaignTransactions"
                        ) {
                          if (metaData["contactNo"] === undefined) {
                            metaData["contactNo"] = true;
                            return (
                              <>{collection["doc"][orderID]["contactNo"]}</>
                            );
                          } else {
                            return <></>;
                          }
                        }
                        return <></>;
                      });
                    }
                    return (
                      <tr key={Math.random()}>
                        <td>{index + 1}</td>
                        <td>{orderID}</td>
                        <td>
                          {data.map((collection, index) => {
                            if (
                              collection["collectionName"] ===
                              "userTransactions"
                            ) {
                              if (metaData["userTransactions"] === undefined) {
                                metaData["userTransactions"] = true;
                                transactionDetails = collection["doc"][orderID];
                                return (
                                  <>
                                    {getCampaignTitles(
                                      collection["doc"][orderID]["campaigns"]
                                    )}
                                  </>
                                );
                              } else {
                                return <></>;
                              }
                            }
                            return <></>;
                          })}
                        </td>

                        <td>{checkoutType}</td>
                        <td>
                          {data.map((collection, index) => {
                            if (
                              collection["collectionName"] ===
                              "userTransactions"
                            ) {
                              if (metaData["address"] === undefined) {
                                metaData["address"] = true;
                                return (
                                  <>{collection["doc"][orderID]["address"]}</>
                                );
                              } else {
                                return <></>;
                              }
                            }
                            return <></>;
                          })}
                        </td>

                        <td>
                          {data.map((collection, index) => {
                            if (
                              collection["collectionName"] ===
                              "campaignTransactions"
                            ) {
                              if (metaData["date"] === undefined) {
                                metaData["date"] = true;
                                return (
                                  <>
                                    {new Date(
                                      collection["doc"][orderID]["date"]
                                    ).toDateString()}
                                  </>
                                );
                              } else {
                                return <></>;
                              }
                            }
                            return <></>;
                          })}
                        </td>
                        <td>
                          {data.map((collection, index) => {
                            if (
                              collection["collectionName"] ===
                              "campaignTransactions"
                            ) {
                              if (metaData["contactNo"] === undefined) {
                                metaData["contactNo"] = true;
                                return (
                                  <>{collection["doc"][orderID]["contactNo"]}</>
                                );
                              } else {
                                return <></>;
                              }
                            }
                            return <></>;
                          })}
                        </td>
                        <td>{status}</td>

                        <td className="text-center">
                          {status === "paid" ? (
                            <></>
                          ) : (
                            <Button
                              onClick={() => {
                                var tempData = fullData;
                                tempData["payment"] = "paid";
                                var _transactionData = [];
                                var checkoutType = "";
                                if (tempData["checkoutType"] != undefined) {
                                  checkoutType = tempData["checkoutType"];
                                }
                                if (
                                  typeof tempData["dataAfterVerify"] ===
                                  "string"
                                ) {
                                  _transactionData = JSON.parse(
                                    tempData["dataAfterVerify"]
                                  );
                                } else {
                                  _transactionData =
                                    tempData["dataAfterVerify"];
                                }

                                tempData["dataAfterVerify"] = _transactionData;
                                Firestore.update("pending", orderID, tempData);

                                console.log(tempData, _transactionData);
                                _transactionData.forEach((item) => {
                                  if (
                                    item.collectionName === "userTransactions"
                                  ) {
                                    for (const key in item.doc) {
                                      if (
                                        Object.hasOwnProperty.call(
                                          item.doc,
                                          key
                                        )
                                      ) {
                                        const element = item.doc[key];
                                        element["checkoutType"] = checkoutType;
                                        console.log(
                                          item.collectionName,
                                          ` item doc`,
                                          element,
                                          key
                                        );
                                      }
                                    }
                                    // var tObj = {};
                                    // tObj[key] = element;
                                    // Firestore.saveWithId(
                                    //   item.collectionName,
                                    //   item.docId,
                                    //   tObj
                                    // );
                                  } else {
                                  }
                                  Firestore.saveWithId(
                                    item.collectionName,
                                    item.docId,
                                    item.doc
                                  );
                                });

                                console.log(orderID, fullData);

                                setPendingTransactions(
                                  pendingTransactions.filter(
                                    (v, i) => v["Id"] != orderID
                                  )
                                );
                                setTransactions(
                                  pendingTransactions.filter(
                                    (v, i) => v["Id"] != orderID
                                  )
                                );
                              }}
                              className="btn mr-4"
                              style={{ fontSize: "14px" }}
                            >
                              Mark as paid
                            </Button>
                          )}
                          <Button
                            onClick={() => {
                              setshowDetailPopUp(true);
                              setGrandTotal(transactionDetails.grandTotal);
                              setSelectedTransaction(transactionDetails);
                              console.log(
                                `transactionDetails`,
                                transactionDetails
                              );
                              setSelectedCheckoutType(checkoutType);
                              console.log(
                                `typeof selectedTransaction`,
                                selectedTransaction
                              );
                            }}
                            className="btn btn-icon btn-round"
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Col>

      <Modal
        size="xl"
        show={showDetailPopUp}
        onHide={() => setshowDetailPopUp(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        variant="warning"
      >
        <Modal.Header className="darkbg" closeButton>
          <Modal.Title
            className="white-color  font-large"
            id="example-modal-sizes-title-lg"
          >
            Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-item-center flex-column darkbg">
          <div className="table-responsive">
            <Table className="table tablesorter " id="details">
              <thead className=" text-primary">
                <tr>
                  <th className="text-center">Campaign</th>
                  <th className="text-center">Payment Gateway</th>
                  <th className="text-center">Product</th>
                  <th className="text-center">prize</th>
                  <th className="text-center">Purchase</th>
                  <th className="text-center">Price</th>
                  <th className="text-center">Coupons</th>
                  <th className="text-center">
                    Grand Total ({parseFloat(grandTotal).toFixed(2)})
                  </th>
                </tr>
              </thead>
              <tbody>
                {generateTableRows(selectedTransaction["campaigns"]).map(
                  (campaign) => {
                    return (
                      <tr key={Math.random()}>
                        <td className="text-center">{campaign.title}</td>

                        <td>{selectedCheckoutType}</td>
                        <td className="text-center">
                          {campaign.product.title}
                        </td>
                        <td className="text-center">{campaign.prize.title}</td>
                        <td className="text-center">{campaign.count}</td>
                        <td className="text-center">
                          {campaign.product.price}
                        </td>
                        <td className="text-center">
                          {campaign.product.coupons.map((coupon) => (
                            <span
                              key={Math.random()}
                              className="badge badge-danger ml-1"
                            >
                              {coupon}
                            </span>
                          ))}
                        </td>

                        <td className="text-center">
                          {(
                            parseFloat(campaign.product.price) * campaign.count
                          ).toFixed(2)}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          </div>
          <div className="d-flex x justify-content-center align-item-center flex-row">
            <Button
              type="submit"
              className="btn btn-icon btn-round  medium-button "
              onClick={() => setshowDetailPopUp(false)}
            >
              Cancel
            </Button>{" "}
          </div>
        </Modal.Body>
      </Modal>
    </Row>
  );
};

export default PendingTransaction;
