import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Actions from "../actions/form-actions";
import "./stylesheet/campaign.css";
import Form from "react-bootstrap/Form";
import { CompactPicker  } from "react-color";
const Colors = () => { 
  const [alert, setalert] = useState(false);
  const [alertMsg, setalertMsg] = useState("");
  const [colorsModel, setColorsModel] = useState({});
  useEffect(() => {
    try {
      Actions.data("colors", function (documents) {
        var obj={}
        for( var key in documents ){
          obj= documents[key]
          }
          delete obj.id  
          setColorsModel(obj) 
      });
    } catch (error) {}
  }, []);
 
  const handleSave = (e) => {
    console.log("colors code : ", JSON.stringify(colorsModel));
      try {
        
        if (Actions.validate(colorsModel.primary) && Actions.validate(colorsModel.onPrimary) && Actions.validate(colorsModel.secondary) )
        {    
          Actions.saveWithId("colors","Wnl0eo6sRHBbUYeD8Erz",colorsModel)
            Actions.showAlert(
              "Information has been saved",
              setalertMsg,
              setalert,
              "2000"
            );  
        }
         else { 
          Actions.showAlert(
            "Please select all colors ",
            setalertMsg,
            setalert,
            "2000"
          );
       }
      } catch (error) {}
  };

  const handleSecondaryChange = (color) => {
    setColorsModel({
      ...colorsModel ,
      secondary: color.hex 
    });
  };
  const handlePrimaryChange = (color) => {
    setColorsModel({
      ...colorsModel ,
      primary: color.hex,
    });
  };
  const handleOnPrimaryChange = (color) => {
    setColorsModel({
      ...colorsModel ,
      onPrimary: color.hex,
    });
  };

  return (
    <Row>
      <Col md={12}>
        <Card>
          {/* create Article  Started */}
          <Card.Body>
            <Card.Title className="d-flex justify-content-between">
              <h2> Color Setting </h2>
              {alert ? (
                <div
                  style={{ backgroundColor: "#e14eca", width: "50%" }}
                  className="alert alert-danger d-flex align-self-center justify-content-center  "
                  role="alert"
                >
                  <strong>{alertMsg} </strong>
                </div>
              ) : (
                ""
              )}
            </Card.Title>
            <>
              <Form>
                <Row>
                  <Col
                    lg={6}
                    md={6}
                    className={"d-flex justify-content-center"}
                    sm={12}
                  >
                    <Form.Group>
                       <h4>Primary Color</h4> 
                      <CompactPicker onChangeComplete={handlePrimaryChange} />
                      <div
                        style={{
                          height: "50px",
                          width: "50px",
                          backgroundColor: colorsModel.primary,
                          borderRadius:"50%"
                        }}
                        className={"ml-2 mt-3"}
                      >
                        {" "}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    className={"d-flex justify-content-center"}
                    sm={12}
                  >
                    <Form.Group>
                       <h4>Secondary Color</h4>
                      <CompactPicker onChangeComplete={handleSecondaryChange} />
                      <div
                        style={{
                          height: "50px",
                          width: "50px",
                          backgroundColor: colorsModel.secondary,
                          borderRadius:"50%"
                        }}
                        className={"ml-2 mt-3"}
                      >
                        {" "}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col
                    lg={12}
                    md={12}
                    className={"d-flex justify-content-center"}
                    sm={12}
                  >
                    <Form.Group>
                      <h4>Font Color</h4>
                      <CompactPicker onChangeComplete={handleOnPrimaryChange} />
                      <div
                        style={{
                          height: "50px",
                          width: "50px",
                          backgroundColor: colorsModel.onPrimary,
                          borderRadius:"50%"
                        }}
                        className={"ml-2 mt-3"}
                      >
                        {" "}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <div className="card-footer d-flex justify-content-center">
               
                <Button
                  size={"lg"}
                  onClick={handleSave}
                > 
                Save
                  </Button>
              </div>
            </>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Colors;
