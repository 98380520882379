export const CrudType = {
    insert: "insert",
    update: "update",
    delete: "delete",
    read: "read",
}


export const EditType = {
    create: "create",
    edit: "edit",
    delete: "delete"
}