import React, { useContext } from "react";
import State from "./context/State";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { firebaseAuth } from "./provider/authprovider";

import Sidebar from "./_partials/sidebar";
import Navbar from "./_partials/navbar";
import Footer from "./_partials/footer";

import Login from "./components/auth/login";
import Campaigns from "./components/pages/campaigns/campaigns";
import Winners from "./components/pages/winners/winners";
import Luckydraw from "./components/pages/luckydraw/luckydraw";
import Transaction from "./components/pages/transactions/transaction";
import Colors from "./components/pages/colors/colors";
import Currency from "./components/pages/curr/curr";
import FakeWinner from "./components/pages/fakewinner/fakewinner";
import Page from "./components/pages/pages/pages";
import SubAdmin from "./components/pages/subAdmin/subAdmin";
import PendingTransaction from "./components/pages/transactions/pending-transaction";
import LinksPage from "./components/pages/links/links";
import NotificationsPage from "./components/pages/notifications/notifications";
import Settings from "./components/pages/settings/settings";
import Users from "./components/pages/users/users";
// import Currency from "./components/pages/currency/currency";

const App = () => {
  const { token, setToken } = useContext(firebaseAuth);
  setToken(localStorage.getItem("token"));
  return (
    <State>
      <Switch>
        <Router>
          {token === null ? <Route path="/" children={<Login />} /> : ""}

          {token === null ? (
            <Redirect to="/"></Redirect>
          ) : (
            <div className="wrapper">
              <Sidebar />
              <div className="main-panel">
                <Navbar />
                <div className="content">
                  <Route
                    exact
                    path="/campaigns:status"
                    children={<Campaigns />}
                  />
                  <Route exact path="/" children={<Users />} />
                  <Route path="/winners" children={<Winners />} />
                  <Route path="/luckydraw" children={<Luckydraw />} />
                  <Route path="/tansactions" children={<Transaction />} />
                  <Route
                    path="/approve-tansactions"
                    children={<PendingTransaction />}
                  />
                  <Route
                    path="/notifications"
                    children={<NotificationsPage />}
                  />
                  <Route path="/colors" children={<Colors />} />
                  <Route path="/currency" children={<Currency />} />
                  <Route path="/fakewinner" children={<FakeWinner />} />
                  <Route path="/pages" children={<Page />} />
                  <Route path="/links" children={<LinksPage />} />
                  <Route path="/subadmin" children={<SubAdmin />} />
                  <Route path="/settings" children={<Settings />} />
                  <Route path="/users" children={<Users />} />

                  <div>
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Router>
      </Switch>
    </State>
  );
};

export default App;
