import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import WinnerCard from "./card/winner-card";
import generateHash from "../../../helpers/hash";
import { Firestore } from "../../../dao/firebase/firebase-firestore";
import { useLocation } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import Flex from "../../flex-box/flex";
const Winners = (props) => {
  const [winners, setWinners] = useState([]);
  const [filteredWinners, setFilteredWinners] = useState([]);
  // const [showActive, setshowActive] = useState("Active");
  const location = useLocation();
  console.log(location.campaign?.id);
  /* reteriving data form firebase before page load */
  useEffect(() => {
    // console.log("use effect");
    // const tempWinners = getCampaignsWinners(winners);
    // setWinners(tempWinners);
    // console.log("winners", JSON.stringify(tempWinners));
    if (location.campaign !== undefined || location.campaign != null) {
      try {
        Firestore.document(
          "winners",
          location.campaign?.id,
          function (document) {
            const tempWinners = getCampaignsWinners(document.data);
            setWinners(tempWinners);
            console.log("winners", JSON.stringify(tempWinners));
          }
        );

        // Actions.data("winners", function (documents) {
        //   setWinners(documents);
        // });
      } catch (error) {}
    } else {
      Firestore.allDocs("winners", function (documents) {
        var _winners = [];
        documents.forEach((doc) => {
          const data = doc.data();
          Object.keys(data).forEach((key, index) => {
            _winners.push(data[key]);
          });
          // console.log("winners", JSON.stringify(tempWinners));
        });
        const tempWinners = getCampaignsWinners(_winners);
        setWinners(tempWinners);
      });
    }
  }, []);

  let tempWinners = [];
  const handleOnSearch = (e) => {
    console.log(e.target.value);
    tempWinners = winners.filter(
      (winner) =>
        winner.firstName === e.target.value ||
        winner.lastName === e.target.value ||
        winner.firstName.toLowerCase() === e.target.value ||
        winner.lastName.toLowerCase() === e.target.value
    );
  };
  const search = (e) => {
    console.log(tempWinners);
    setFilteredWinners(tempWinners);
  };
  const Filters = () => {
    return (
      <Form.Row>
        <Col md={4}>
          <Form.Control
            type={"text"}
            name={"Winner Name"}
            placeholder={"First Name or Last Name"}
            onChange={handleOnSearch}
          />
        </Col>
        <Col md={2}>
          <Flex>
            <Button size={"sm"} onClick={search}>
              Search
            </Button>
          </Flex>
        </Col>
      </Form.Row>
    );
  };

  return (
    <Container>
      <h2> Winner </h2>
      <Filters />
      <Row>
        {filteredWinners.length === 0 ? (
          winners.length > 0 ? (
            winners.map((winner) => (
              <Col sm={12} md={6} lg={6} className="mt-5">
                <WinnerCard key={generateHash()} data={winner}></WinnerCard>{" "}
              </Col>
            ))
          ) : (
            <p>Loading</p>
          )
        ) : (
          filteredWinners.map((winner) => (
            <Col sm={12} md={6} lg={4} className="mt-5">
              <WinnerCard key={generateHash()} data={winner}></WinnerCard>
            </Col>
          ))
        )}
      </Row>
    </Container>
  );
};

const getCampaignsWinners = (campaign_winners) => {
  const winnersArray = [];
  for (const winner in campaign_winners) {
    winnersArray.push(campaign_winners[winner]);
  }
  return winnersArray;
};

export default Winners;
