import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { firebaseAuth } from "../../provider/authprovider";
import { Firestore } from "../../dao/firebase/firebase-firestore";
export const Login = () => {
  const { handleSignin, inputs, setInputs, errors } = useContext(firebaseAuth);

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    console.log(JSON.stringify(inputs));
  };
  const handleLogin = (e) => {
    Firestore.dataWithMultipleCondition(
      "admins",
      { field: "email", filter: "==", value: inputs.email },
      { field: "password", filter: "==", value: inputs.password },
      function (data) { 
        if (data.length !== 0) {
          handleSignin();
        }
      }
    );
  };
  return (
    <Row
      className={"d-flex flex-column justify-content-center align-items-center"}
      style={{ height: "100vh" }}
    >
      <Col md={4} sm={12}>
        <Card>
          <Card.Body className="card card-user">
            <>
              <Container>
                <div className="author">
                  <div className="block block-one"></div>
                  <div className="block block-two"></div>
                  <div className="block block-three"></div>
                  <div className="block block-four"></div>
                  <Link to="/">
                    <h5 className="title" style={{ fontSize: "32px" }}>
                      {" "}
                      LOGIN{" "}
                    </h5>
                  </Link>
                  <span>Login here to acess the Admin Panel </span>
                </div>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={inputs.email || ""}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Username"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={inputs.password || ""}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Password"
                    />
                  </Form.Group>
                </Col>
                <div className="card-footer d-flex justify-content-center align-items-center">
                  <Button onClick={handleLogin} variant="primary">
                    Login
                  </Button>{" "}
                </div>
                {errors.length > 0
                  ? errors.map((error) => (
                      <p key={Math.random()} style={{ color: "red" }}>
                        {error}
                      </p>
                    ))
                  : null}
              </Container>
            </>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
