import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import generateHash from "../../../helpers/hash";

import CampaignCard from "./components/campaign-card";
import CampaignForm from "./components/campaign-form";
import CampaignFilters from "./components/campaign-filter";
import Flex, { FlexProps } from "../../flex-box/flex";
import CampaignModel from "../../../models/campaign-model";
import Dialog from "../../dialog/dialog";
import { EditType } from "../../../config/forms/form";
import Actions from "../actions/form-actions";
import "./stylesheet/campaign.css";
import { useParams } from "react-router";
import { Firestore } from "../../../dao/firebase/firebase-firestore";
const Campaigns = () => {
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [formEditState, setEditForm] = useState(false);
  const [formEditType, setFormEditType] = useState(EditType.create);
  const [activeCampaign, setActiveCampaign] = useState(CampaignModel);
  const [showDialog, setShowDialog] = useState(false);
  let parm = useParams();
  useEffect(() => {
    try {
      Actions.data("campaigns", function (documents) {
        setCampaigns(documents);
      });
    } catch (error) {}
  }, []);

  const insertCampaignData = (campaign) => {
    const temp = campaigns;
    temp.push(campaign);
    setCampaigns(temp);

    Firestore.saveCallback("campaigns", campaign, (docId, message) => {
      if (docId === null) {
      } else {
        console.log(`docId,message data when inserted`, docId, message);
        var _campaign = campaign;
        _campaign["id"] = docId;
        console.log(`_campaign`, _campaign);
        Firestore.update("campaigns", docId, _campaign);
      }
    });
    setActiveCampaign({});
    setFormEditType(EditType.create);
  };
  const updateCampaignData = (campaignId, campaign) => {
    const temp = campaigns.filter((v, k) => v.id !== campaignId);
    temp.push(campaign);
    setCampaigns(temp);
    Actions.update("campaigns", campaignId, campaign);
    setActiveCampaign({});
    setFormEditType(EditType.create);
  };

  const onFormEdit = (campaign, editType) => {
    setEditForm(true);
    setFormEditType(editType);
    setActiveCampaign(campaign);
  };
  const afterFormSubmit = (campaign, valitdateStatus, editType) => {
    if (valitdateStatus) {
      if (campaign.status === "") {
        if (campaign.soldCount === "") campaign.soldCount = "0";
        campaign.status = "active";
      }
      switch (editType) {
        case EditType.create:
          insertCampaignData(campaign);
          break;
        case EditType.edit:
          updateCampaignData(campaign.id, campaign);
          break;
        case EditType.delete:
          break;
        default:
          break;
      }
      setEditForm(false);
    } else {
      setEditForm(true);
    }
  };
  const onDeletePress = (campaign) => {
    setActiveCampaign(campaign);
    setShowDialog(true);
    console.log(showDialog);
  };
  const onDeleteYes = () => {
    console.log("campaigns", activeCampaign.id);
    Actions.delete("campaigns", activeCampaign.id);
    const afterDeleteCampaigns = campaigns.filter(
      (campaign, k) => campaign.id !== activeCampaign.id
    );
    setCampaigns(afterDeleteCampaigns);
    setShowDialog(false);
  };

  const onDialogClose = () => {
    setShowDialog(false);
    console.log(showDialog);
  };

  const FilteredCards = () => {
    return (
      <div className="cards-container">
        {filteredCampaigns.length > 0
          ? filteredCampaigns.map((campaign) => (
              <CampaignCard
                key={generateHash()}
                data={campaign}
                onEdit={onFormEdit}
                onDelete={onDeletePress}
              />
            ))
          : campaigns
              .filter((item) =>
                parm.status === "completed"
                  ? item.status === parm.status
                  : parm.status === "finished"
                  ? item.status === parm.status
                  : item.status === "completed" ||
                    "finished" ||
                    "active" ||
                    "inactive"
              )
              .map((campaign) => (
                <CampaignCard
                  onEdit={onFormEdit}
                  key={generateHash()}
                  data={campaign}
                  onDelete={onDeletePress}
                />
              ))}
      </div>
    );
  };

  return (
    <>
      {showDialog ? (
        <Dialog
          title={"Delete Campaign"}
          show={showDialog}
          onClose={onDialogClose}
        >
          <Flex justifyContent={FlexProps.justifyContent.spaceBetween}>
            <Button size={"sm"} className={""} onClick={onDeleteYes}>
              {"Yes"}
            </Button>
            <Button size={"sm"} className={""} onClick={onDialogClose}>
              {"Cancel"}
            </Button>
          </Flex>
        </Dialog>
      ) : (
        <></>
      )}
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Flex justifyContent={FlexProps.justifyContent.flexEnd}>
                <Button
                  size={"sm"}
                  onClick={() => {
                    setActiveCampaign(CampaignModel);
                    setFormEditType(EditType.create);
                    setEditForm(!formEditState);
                  }}
                >
                  {formEditState ? "Cancel" : "Create Campaign"}
                </Button>
              </Flex>
              {formEditState ? (
                <CampaignForm
                  editType={formEditType}
                  data={
                    formEditType === "create" ? CampaignModel : activeCampaign
                  }
                  afterSubmit={afterFormSubmit}
                />
              ) : (
                <div>
                  <CampaignFilters
                    onSearch={(campaignName, campaignStatus) => {
                      let _filteredCampaigns = campaigns.filter(
                        (v, k) =>
                          v.title === campaignName ||
                          v.status === campaignStatus
                      );

                      if (_filteredCampaigns.length === 0) {
                        alert("no match found");
                      }
                      setFilteredCampaigns(_filteredCampaigns);
                      console.log(
                        campaignName,
                        campaignStatus,
                        _filteredCampaigns
                      );
                    }}
                  />
                  {filteredCampaigns.length === 0 && campaigns.length === 0 ? (
                    <p>Loading...</p>
                  ) : (
                    <FilteredCards />
                  )}
                </div>
              )}
              {/* Article List Ended */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Campaigns;
