import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Actions from "../actions/form-actions";
import Table from "react-bootstrap/Table";
import "./stylesheet/subAdmin.css";
import Form from "react-bootstrap/Form";
import { firebaseAuth } from "../../../provider/authprovider";
import Flex, { FlexProps } from "../../flex-box/flex";
const SubAdmin = () => {
  const tableheader = [
    { header: "S:NO" },
    { header: "Name" },
    { header: " Email" },
    { header: "Password" },
    { header: "Edit" },
    { header: "Delete" },
  ];
  const [alert, setalert] = useState(false);
  const [alertMsg, setalertMsg] = useState("");
  const [admin, setAdmin] = useState({ status: "active" });
  const [adminList, setAdminList] = useState([]);
  const [authError, setAuthError] = useState(false);
  const [addNewAdmin, setAddNewAdmin] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(false);

  const [showByAdminName, setshowByAdminName] = useState("");
  const { handleSignup, errors } = useContext(firebaseAuth);

  useEffect(() => {
    try {
      Actions.data("admins", function (documents) {
        setAdminList(documents);
      });
    } catch (error) {}
  }, []);
  const filterByName = (e) => {
    setshowByAdminName(e.target.value);
  };
  const handleSave = (e) => {
    e.preventDefault();

    try {
      if (edit) {
        var tempAdminList = adminList;
        tempAdminList[currentIndex] = admin;
        Actions.update("admins", adminList[currentIndex].id, admin);
        setAdminList(tempAdminList);
        setAddNewAdmin(false);
        setEdit(false);
      } else if (
        Actions.validate(admin.name) &&
        Actions.validate(admin.email) &&
        Actions.validate(admin.password)
      ) {
        console.log("Admin code GGG: ", JSON.stringify(admin));
        handleSignup(admin.email, admin.password, function (bool) {
          if (bool) {
            Actions.save("admins", admin);
            setAddNewAdmin(false);

            var tempAdminList = [];
            tempAdminList = adminList;
            tempAdminList.push(admin);
            setAdminList(tempAdminList);
          } else {
            setAuthError(true);
            setTimeout(() => {
              setAuthError(false);
            }, 3000);
          }
        });

        Actions.showAlert(
          "Information has been saved",
          setalertMsg,
          setalert,
          "2000"
        );
        setAdmin({});
      } else {
        Actions.showAlert(
          "Please proper fill the fields  ",
          setalertMsg,
          setalert,
          "2000"
        );
      }
    } catch (error) {}
  };

  const handleChange = (e) => {
    setAdmin({
      ...admin,
      [e.target.name]: e.target.value,
    });
    console.log("Admin code GGG: ", JSON.stringify(admin));
  };
  const FormFeedback = (props) => {
    return (
      <Form.Control.Feedback type="invalid">
        {props.message}
      </Form.Control.Feedback>
    );
  };

  return (
    <>
      {alert ? (
        <div
          style={{ backgroundColor: "#e14eca", width: "50%" }}
          className="alert alert-danger d-flex align-self-center justify-content-center  "
          role="alert"
        >
          <strong>{alertMsg} </strong>
        </div>
      ) : (
        ""
      )}
      {authError
        ? errors.map((error) => (
            <p key={Math.random()} style={{ color: "red" }}>
              {error}
            </p>
          ))
        : null}
      <Row>
        {addNewAdmin ? (
          <Col md={12}>
            <Card>
              <Card.Body>
                <Flex justifyContent={FlexProps.justifyContent.flexEnd}>
                  <Button
                    size={"sm"}
                    onClick={() => {
                      setAddNewAdmin(!addNewAdmin);
                      setEdit(false);
                    }}
                  >
                    {"Back"}
                  </Button>
                </Flex>
                <>
                  <Card.Title className="d-flex justify-content-center">
                    <h2 className={""}> Add Admin </h2>
                  </Card.Title>
                  <>
                    <Form noValidate>
                      {/* gernal info */}
                      <Form.Row className="d-flex justify-content-center">
                        <Form.Group as={Col} md={4}>
                          <Form.Label className={""}> Name</Form.Label>
                          <Form.Control
                            value={admin.name}
                            onChange={handleChange}
                            type={"text"}
                            name={"name"}
                            placeholder={"Enter Name"}
                          />
                          <FormFeedback message={"Please provide  Name"} />
                        </Form.Group>
                        <Form.Group as={Col} md={4}>
                          <Form.Label className={""}> Email</Form.Label>
                          <Form.Control
                            value={admin.email}
                            onChange={handleChange}
                            type={"text"}
                            name={"email"}
                            placeholder={" Enter Email"}
                          />
                          <FormFeedback message={"Please provide  Email"} />
                        </Form.Group>
                      </Form.Row>

                      <Form.Row className="d-flex justify-content-center">
                        <Form.Group as={Col} md={4}>
                          <Form.Label className={""}> Password</Form.Label>
                          <Form.Control
                            value={admin.password}
                            onChange={handleChange}
                            type={"text"}
                            name={"password"}
                            placeholder={"Enter Password"}
                          />
                          <FormFeedback message={"Please provide Password"} />
                        </Form.Group>
                      </Form.Row>
                    </Form>
                    <div className="d-flex justify-content-center">
                      <Button onClick={handleSave}>
                        {edit ? "Update" : "Add"}
                      </Button>
                    </div>
                  </>
                </>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          <Col md={12}>
            <Card>
              <Card.Body>
                <Flex justifyContent={FlexProps.justifyContent.flexEnd}>
                  <Button
                    size={"sm"}
                    onClick={() => {
                      setAddNewAdmin(!addNewAdmin);
                    }}
                  >
                    {"Add New Admin"}
                  </Button>
                </Flex>
                <Card.Title className="card-title">
                  <h2> Admins </h2>
                  {/* filter Area started */}
                  {/* Filling Searchable dropdown with admins */}

                  <Form.Group className="mt-3" controlId="searchbyDoc">
                    <span className="mr-4 font-small  ">
                      Search By Admin Name
                    </span>
                    <Form.Control
                      onChange={filterByName}
                      list="adminslist"
                      name="adminslist"
                      className="form-control"
                      style={{
                        width: "29%",
                        display: "unset",
                        marginLeft: "61px",
                      }}
                    />
                    <datalist id="adminslist">
                      {adminList.map((singleAdmin) => (
                        <option key={Math.random()} value={singleAdmin.name} />
                      ))}
                    </datalist>
                  </Form.Group>

                  {/* Filling Searchable dropdown with adminList

              {/* filter Area ended */}
                </Card.Title>
                <div className="table-responsive">
                  <Table className="table tablesorter " id="">
                    <thead className=" text-primary">
                      <tr>
                        {tableheader.map((item) => (
                          <th className="text-center" key={Math.random()}>
                            {item.header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {adminList.map((item, index) => (
                        <tr key={Math.random()}>
                          <td className="text-center">{index}</td>
                          <td className="text-center">{item.name}</td>
                          <td className="text-center">{item.email}</td>
                          <td className="text-center">{item.password}</td>
                          <td className="text-center">{item.password}</td>
                          <td className="text-center">{item.password}</td>
                          <td className="text-center">
                            <Button
                              onClick={(e) => {
                                setEdit(true);
                                setCurrentIndex(index);
                                setAdmin(adminList[index]);
                                setAddNewAdmin(true);
                              }}
                            >
                              <i className={"fa fa-2x fa-edit"}></i>
                            </Button>
                          </td>
                          <td className="text-center">
                            <Button
                              onClick={(e) => {
                                Actions.delete("admins", adminList[index].id);
                                const tempAdminList = adminList.filter(
                                  (_admin, _index) => _index !== index
                                );
                                setAdminList(tempAdminList);
                              }}
                            >
                              <i className={"fa fa-2x fa-trash"}></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default SubAdmin;
