import React from "react";
import { Button, Card } from "react-bootstrap";

const NotificationCard = ({ notification, onSend }) => {
  return (
    <Card style={{ width: "18rem" }}>
      <Card.Img variant="top" src={notification.image} />
      <Card.Body
        className={
          "d-flex flex-column align-items-center justify-content-center"
        }
      >
        <Card.Title>{notification?.title}</Card.Title>
        <Card.Text>{notification?.body}</Card.Text>
        <Button variant="primary" onClick={onSend}>
          Send Notification
        </Button>
      </Card.Body>
    </Card>
  );
};

export default NotificationCard;
