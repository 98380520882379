import React, { useReducer } from "react";

import Context from "./Context";
import Reducer from "./Reducer";
import { MenuClick } from "./types/types";

export const State = (props) => {
  const initialState = {
    pageTitle: null,
    sideBarState: [
      {
        title: "EbigWin",
        subitems: [],
      },
      {
        title: "Notifications",
        link: "/notifications",
        icon: "fa fa-2x fa-bell",
        className: "",
      },
      {
        title: "Campaigns",
        link: "/campaignsnull",
        icon: "fa fa-2x fa-list-alt",
        className: "",
      },
      {
        title: "Manage Winner",
        icon: "fa fa-2x fa-trophy",
        subitems: [
          {
            title: "Winners",
            link: "/winners",
            icon: "fa fa-2x fa-trophy",
            className: "",
          },
          {
            title: "Campaigns",
            link: "/campaignsfinished",
            icon: "fa fa-2x fa-list-alt",
            className: "",
          },
          {
            title: "Fake Winner",
            link: "/fakewinner",
            icon: "fa fa-2x fa-trophy",
            className: "",
          },
        ],
      },
      {
        title: "Manage Luckydraw",
        icon: "fa fa-2x fa-question-circle",
        subitems: [
          {
            title: "Luckydraw",
            link: "/luckydraw",

            icon: "fa fa-2x fa-question-circle",
            className: "",
          },
          {
            title: "Completed Campaigns",
            link: "/campaignscompleted",
            icon: "fa fa-2x fa-list-alt",
            className: "",
          },
        ],
      },
      {
        title: "Manage Transactions",
        icon: "fa fa-2x fa-university",
        subitems: [
          {
            title: "Transactions",
            link: "/tansactions",
            icon: "fa fa-2x fa-university",
            className: "",
          },
          {
            title: "Approve Transactions",
            link: "/approve-tansactions",
            icon: "fa fa-2x fa-credit-card",
            className: "",
          },
        ],
      },

      {
        title: "Color Setting",
        link: "/colors",
        icon: "fa fa-2x fa-wrench",
        className: "",
      },
      {
        title: "Currency",
        link: "/currency",
        icon: "fa fa-2x fa-usd",
        className: "",
      },
      {
        title: "Pages",
        link: "/pages",
        icon: "fa fa-2x fa-pencil-square",
        className: "",
      },
      {
        title: "Links",
        link: "/links",
        icon: "fa fa-2x fa-link",
        className: "",
      },
      {
        title: "Sub Admins",
        link: "/subadmin",
        icon: "fa fa-2x fa-lock",
        className: "",
      },
      {
        title: "Settings",
        link: "/settings",
        icon: "fa fa-2x fa-cogs",
        className: "",
      },
      {
        title: "Users",
        link: "/users",
        icon: "fa fa-2x fa-users",
        className: "",
      },
    ],

    footerState: [
      {
        title: " ",
        className: "nav-item",
        icon: " tim-icons icon-atom",
        link: "#",
      },
    ],

    dummyCampaigns: [
      {
        title: "Carola",
        soldCount: 109,
        prize: {
          title: "Cap",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, harum. Odio at in, consequatur, tempora quam blanditiis omnis corporis neque cupiditate assumenda unde obcaecati doloremque quod sequi molestias culpa aliquid!",
          image:
            "https://cdn4.iconfinder.com/data/icons/MacBook_Pro/512/leopard.png",
        },
        threshold: 190,
        product: {
          price: 210.2,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, harum. Odio at in, consequatur, tempora quam blanditiis omnis corporis neque cupiditate assumenda unde obcaecati doloremque quod sequi molestias culpa aliquid!",
          image:
            "https://cdn4.iconfinder.com/data/icons/MacBook_Pro/512/leopard.png",
          title: "Card",
        },
        Id: "VsTOOpxwl6KhGCPY6hod",
      },
      {
        Title: "4 Bed Apartment",
        product: {
          title: "Pencil",
          price: 20.01,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, harum. Odio at in, consequatur, tempora quam blanditiis omnis corporis neque cupiditate assumenda unde obcaecati doloremque quod sequi molestias culpa aliquid!",
          image:
            "https://cdn4.iconfinder.com/data/icons/MacBook_Pro/512/leopard.png",
        },
        threshold: 120,
        prize: {
          title: "Mercedes Benz",
          image:
            "https://www.mercedes-benz.com.au/passengercars/_jcr_content/image.MQ6.2.2x.20200812074529.png",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, harum. Odio at in, consequatur, tempora quam blanditiis omnis corporis neque cupiditate assumenda unde obcaecati doloremque quod sequi molestias culpa aliquid!",
        },
        soldCount: 100,
        Id: "uCYiAxM7EU4ocmf7qmfn",
      },
      {
        threshold: 105,
        product: {
          price: 105.05,
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, harum. Odio at in, consequatur, tempora quam blanditiis omnis corporis neque cupiditate assumenda unde obcaecati doloremque quod sequi molestias culpa aliquid!",
          title: "Car",
          image: "https://freepngimg.com/thumb/car/4-2-car-png-hd.png",
        },
        soldCount: 101,
        prize: {
          title: "Laptop",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, harum. Odio at in, consequatur, tempora quam blanditiis omnis corporis neque cupiditate assumenda unde obcaecati doloremque quod sequi molestias culpa aliquid!",
          image:
            "https://cdn4.iconfinder.com/data/icons/MacBook_Pro/512/leopard.png",
        },
        title: "Bugati",
        Id: "xh6v5YqE1AeXCy2l9d6C",
      },
    ],
  };
  const [state, dispatch] = useReducer(Reducer, initialState);

  const menuClick = (pagetitle) => {
    dispatch({
      type: MenuClick,
      payload: pagetitle,
    });
  };

  // ManageDoctors Methods Ended

  return (
    <Context.Provider
      value={{
        sideBarState: state.sideBarState,
        pageTitle: state.pageTitle,
        footerState: state.footerState,
        menuClick,
        dummyCampaigns: state.dummyCampaigns,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default State;
