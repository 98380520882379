import React, { Component } from "react";
export const FlexProps = {
  direction: {
    row: "row",
    column: "column",
  },
  justifyContent: {
    flexStart: "flex-start",
    flexEnd: "flex-end",
    center: "center",
    spaceBetween: "space-between",
  },
  alignItems: {
    flexStart: "flex-start",
    flexEnd: "flex-end",
    center: "center",
  },
};
const Flex = (
  props = {
    className: "",
    justifyContent: "",
    alignItems: "",
    direction: "row",
  }
) => {
  return (
    <div
      className={props.className}
      style={{
        display: "flex",
        flexDirection: props?.direction,
        justifyContent: props?.justifyContent,
        alignItems: props?.alignItems,
      }}
    >
      {props.children}
    </div>
  );
};

export default Flex;
