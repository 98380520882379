import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

import generateHash from "../../../helpers/hash";
import { Firestore } from "../../../dao/firebase/firebase-firestore";

import Flex, { FlexProps } from "../../flex-box/flex";
import { useLocation } from "react-router-dom";
import sendEmail from "../../../helpers/email";
import winnerTemplate from "../../templates/winner";
import buyerTemplate from "../../templates/buyer-template";
import sendMessage from "../../../dao/firebase/fcm";
/*
 * this jsx section creates form Differnt @inouts(textfield,textarea,numbers)
 * @button(facebook,skype and rounded etc) and @Card
 */
const Luckydraw = () => {
  const [, setshowActive] = useState("Active");
  const location = useLocation();

  const [campaign, setCampaign] = useState(
    location.campaign !== undefined ? location.campaign : {}
  );
  const [winners, setWinners] = useState([]);
  const [coupons, setCoupons] = useState({});
  const [numberOfWinner, setNumberOfWinner] = useState(3);
  const [showDraw, setShowDraw] = useState(false);

  useEffect(() => {
    console.info(campaign);
    if (location.campaign !== undefined) {
      setCampaign(location.campaign);
      Firestore.document("coupons", location.campaign.id, function (document) {
        console.info({ message: "document", info: JSON.stringify(document) });
        console.log(JSON.stringify(document.data));
        setCoupons(document.data);
      });
    }

    // Firestore.data("coupons", function (data) {
    //   console.log(JSON.stringify(data));
    // });
    console.log("use Effect");
  }, []);

  const handleChange = (e) => {
    var value = e.target.value;
    setNumberOfWinner(value);
  };

  const handleLuckyDraw = async () => {
    var tempCoupons = [];

    console.log(
      `coupons sent by campaign are ${JSON.stringify(coupons)}, ${
        location.campaign.title
      }`
    );
    for (const coupon in coupons) {
      tempCoupons.push(coupons[coupon]);
    }

    function vlookup(element, input) {
      return element[input];
    }

    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    var tempWinners = [];
    var tempWinnersObj = {};
    var tempBuyers = [];
    var tempBuyersObj = {};
    var exists = [];
    for (var i = 0; i < numberOfWinner; i++) {
      var r = getRandomInt(0, tempCoupons.length - 1);
      var uid = vlookup(tempCoupons, r).userId;

      if (exists.includes(uid)) {
      } else {
        exists.push(uid);
        // eslint-disable-next-line no-loop-func
        var _data = await Firestore.documentAsync("users", uid);
        console.log(`firebaseData`, _data["data"]);
        const id = _data["id"];
        const data = _data["data"];
        var email = data.email;

        if (tempCoupons != null) {
          if (tempWinnersObj.hasOwnProperty(uid)) {
            const data =
              tempWinnersObj[uid]["coupons"][
                getRandomInt(0, tempWinnersObj[uid]["coupons"].length - 1)
              ];
            tempWinnersObj[uid]["coupons"] = [data];
          } else {
            tempWinnersObj[uid] = vlookup(tempCoupons, r);

            tempWinnersObj[uid]["coupons"] = [
              tempWinnersObj[uid]["coupons"][
                getRandomInt(0, tempWinnersObj[uid]["coupons"].length - 1)
              ],
            ];
          }

          tempWinnersObj[uid]["email"] = email;
          tempWinnersObj[uid]["campaign"] = location.campaign.title;
          tempWinnersObj[uid]["image"] = location.campaign.prize.image;
          tempWinnersObj[uid]["prize"] = location.campaign.prize.title;
          tempWinnersObj[uid]["date"] = new Date().toLocaleString();

          tempWinners.push(vlookup(tempCoupons, r));
        }
      }
      //  {
      //   // Firestore.document("users", uid, (_data) => {
      //   //   const id = _data["id"];
      //   //   const data = _data["data"];
      //   //   console.log(`data['email']`, data["email"]);
      //   //   // tempBuyersObj[id]["email"] = data["email"];
      //   // });
      // }
    }

    setWinners(tempWinners);

    console.log("temp winners object", JSON.stringify(tempWinnersObj));

    if (
      (Object.keys(tempWinnersObj).length > 0) &
      (Object.keys(tempWinnersObj).length <= 3)
    ) {
      Firestore.saveWithId("winners", location.campaign.id, tempWinnersObj);
      var tempCampaign = campaign;
      tempCampaign.status = "finished";
      Firestore.saveWithId("campaigns", location.campaign.id, tempCampaign);

      console.log(`looping winners`, tempWinnersObj);
      const winner_coupons = [];
      var winner_names = [];
      var _tokens = [];
      for (const key in tempWinnersObj) {
        setTimeout(async () => {
          const _winner = tempWinnersObj[key];
          console.log(`for in`, _winner);
          console.log(`looping winners`, _winner);
          const _email = _winner["email"];
          const _coupon = _winner["coupons"][0];
          winner_coupons.push(_coupon);
          winner_names.push(_winner.firstName + " " + _winner.lastName);
          const _template = winnerTemplate(location.campaign.title, _coupon);

          var _data = await Firestore.documentAsync("users", key);
          const token = _data.data["token"];
          _tokens.push(token);
          sendMessage(
            `Congratulation for Winning ${location.campaign.title}`,
            "Please check your email for more",
            location.campaign.prize.image, 
            [token]
          );
          sendEmail(
            _email,
            `Congratulation for Winning ${location.campaign.title}`,
            _template
          );
        }, 1200);
      }

      tempCoupons.forEach((item, index) => {
        // userId
        tempWinners.forEach((winner, _index) => {
          setTimeout(async () => {
            if (winner.userId !== item.userId) {
              var updatedData = await Firestore.documentAsync(
                "users",
                item.userId
              );
              var _buyer = updatedData.data;

              console.log(`for in`, _buyer);
              console.log(`looping winners`, _buyer);
              const _email = _buyer["email"];
              const _template = buyerTemplate(
                location.campaign.title,
                winner_coupons.toString(),
                winner_names.toString()
              );
              sendEmail(
                _email,
                `BETTER LUCK NEXT TIME, Try Again to Win ${location.campaign.title}`,
                _template
              );
            }
          }, 1200);
        });
      });
    }
  };

  const CampaignCard = () => {
    return (
      <div key={generateHash()} className="campaign-card">
        <div className="campaign-header">
          <h6>{campaign.title}</h6>
        </div>
        <div className="campaign">
          <div className="campaign-preview ">
            <h6>Open Offer </h6>
            <div className="uppercard-heading mt-3">
              <h3>{campaign.product.title}</h3>
              <h3>{campaign.product.price}</h3>
            </div>

            {campaign?.product?.image !== null ? (
              <img
                className="product-image mt-3"
                src={campaign.product.image}
                alt="product"
              />
            ) : (
              <></>
            )}

            <h5 className="mt-2">{campaign.product.description}</h5>
          </div>
          <Flex className="campaign-info" direction={"column"}>
            <div style={{ flex: 1 }}>
              <div className="progress-container">
                <div className="progress" style={{ boxShadow: "unset" }}></div>
                <span className="progress-text">
                  {campaign.soldCount}/{campaign.threshold} Soldout
                </span>
              </div>
              <h6 className="text-dark">Winning Prize</h6>
              <h2 className="text-dark">{campaign.prize.title}</h2>
              <img
                className="prize-image"
                src={campaign.prize.image}
                alt="product"
              />
              <div className="prize-description">
                <h5 className="text-dark mt-1">{campaign.prize.description}</h5>
              </div>
            </div>
            <Flex justifyContent={FlexProps.justifyContent.flexEnd}>
              <Button
                size={"sm"}
                onClick={() => {
                  setShowDraw(true);
                  setCampaign(campaign);
                }}
              >
                Generate Winner
              </Button>
            </Flex>
          </Flex>
        </div>
      </div>
    );
  };

  const Genrator = () => {
    return (
      <Col
        md={12}
        className="mt-5  justify-content-center align-content-center d-flex flex-column"
      >
        <Container>
          <Form.Group as={Col} md={4}>
            <Form.Label className={""}>No of Winners </Form.Label>
            <Form.Control
              value={numberOfWinner}
              onChange={handleChange}
              type={"number"}
              name={"numberOfWinner"}
              placeholder={" Enter No of Winners"}
            />
          </Form.Group>
        </Container>
        <center>
          <div id="mobile">Draw For {campaign.title}</div>
          <Button size={"sm"} onClick={handleLuckyDraw}>
            Generate Winners
          </Button>
        </center>
        {winners.length > 0 ? (
          <div className="cards-container">
            {winners.map((winner, index) => (
              <div
                key={generateHash()}
                className="campaign example-enter  m-4"
                id={`winner${index + 1}`}
                style={{ flex: "3" }}
              >
                <div
                  className="campaign-preview"
                  style={{ width: "fit-content" }}
                >
                  <h2>Winner</h2>
                  <h3 className="winnertext">
                    {winner.firstName} {winner.lastName}
                  </h3>
                  <span>{winner.coupons}</span>
                  <br />
                  <span>{winner.contactnumber}</span>
                  <br />
                  <span>{winner.email}</span>
                </div>
                <div className="campaign-info">
                  <h2 className="winnertext">{winner.prize}</h2>
                  <img widht={"100%"} src={winner.image} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </Col>
    );
  };

  const Filters = () => {
    return (
      <Col md={12} className="mt-5">
        <span className="mr-4 font-small ">
          {" "}
          Search Luckydraws Which are :{" "}
        </span>
        <span style={{ display: "unset" }} className="custom-control ">
          <Form.Check
            custom
            inline
            label="Generated"
            name="statusFilter"
            type={"radio"}
            value="generated"
            onClick={(e) => setshowActive(e.target.value)}
            id={"generated"}
          />
          <Form.Check
            custom
            inline
            label="Pending"
            name="statusFilter"
            type={"radio"}
            value="pending"
            onClick={(e) => setshowActive(e.target.value)}
            id={"pending"}
          />
        </span>
        <div className="cards-container">
          {Object.keys(campaign).length > 0 ? (
            <CampaignCard key={generateHash()} data={campaign} />
          ) : (
            <></>
          )}
        </div>
      </Col>
    );
  };

  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Body>
            {/* campaign  ended */}
            {/*  campaign List */}
            <h2> Luckydraw </h2>
            {showDraw ? (
              <Flex justifyContent={FlexProps.justifyContent.flexEnd}>
                <Button
                  type="submit"
                  size={"sm"}
                  onClick={() => {
                    setShowDraw(!showDraw);
                    setWinners([]);
                  }}
                >
                  {""}Back to LuckyDraw {""}
                </Button>
              </Flex>
            ) : (
              ""
            )}

            {showDraw ? <Genrator /> : <Filters />}
            {/* Article List Ended */}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Luckydraw;

// VsTOOpxwl6KhGCPY6hod
// uCYiAxM7EU4ocmf7qmfn
// xh6v5YqE1AeXCy2l9d6C
