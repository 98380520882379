import firebase from "firebase";
import "firebase/auth";
import ErrorType from "../../config/errors/error-types";
import { FirebaseStorage } from "../../config/firebase/firebase-config";

const firestore = firebase.firestore();
firestore.settings({
  timestampsInSnapshots: true,
});

var message = { message: "", error: ErrorType.undefined };

export const Firestore = {
  data: (collection, callback) => {
    firestore
      .collection(collection)
      .get()
      .then((snapshot) => {
        const doc_data = [];

        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          doc_data.push(data);
        });
        callback(doc_data);
      });
  },

  allDocs: (collection, callback) => {
    firestore
      .collection(collection)
      .get()
      .then((snapshot) => {
        callback(snapshot.docs);
      });
  },
  // helper
  loopDocs: (docs, callback) => {
    docs.forEach((doc) => {
      callback(doc.id, doc.data());
    });
  },

  dataWithCondition: (
    collection,
    condition = { field: "", filter: "", value: "" },
    callback
  ) => {
    firestore
      .collection(collection)
      .where(condition.field, condition.filter, condition.value)
      .get()
      .then((querySnapshot) => {
        const documents = [];
        querySnapshot.forEach((doc) =>
          documents.push({ data: doc.data(), Id: doc.id })
        );
        callback(documents);
      })
      .catch((error) => {
        console.log("No matching documents.", error);
      });
  },
  dataWithMultipleConditionSingle: (
    collection,
    conditionFirst = { field: "", filter: "", value: "" },
    callback
  ) => {
    firestore
      .collection(collection)
      .get()
      .then((querySnapshot) => {
        const documents = [];
        querySnapshot.forEach((doc) =>
          documents.push({ data: doc.data(), Id: doc.id })
        );
        callback(documents);
      });
    // if (snapshot.empty) {
    //     console.log("No matching documents.");
    //
    // }
    // snapshot.forEach((doc) => {
    //     console.log(doc.id, "=>", doc.data());
    // });
  },
  dataWithMultipleCondition: (
    collection,
    conditionFirst = { field: "", filter: "", value: "" },
    conditionThird = { field: "", filter: "", value: "" },
    callback
  ) => {
    firestore
      .collection(collection)
      .where(conditionFirst.field, conditionFirst.filter, conditionFirst.value)
      .where(conditionThird.field, conditionThird.filter, conditionThird.value)
      .get()
      .then((querySnapshot) => {
        const documents = [];
        querySnapshot.forEach((doc) =>
          documents.push({ data: doc.data(), Id: doc.id })
        );
        callback(documents);
      });
    // if (snapshot.empty) {
    //     console.log("No matching documents.");
    //
    // }
    // snapshot.forEach((doc) => {
    //     console.log(doc.id, "=>", doc.data());
    // });
  },
  limitedData: (collection, limit, callback) => {
    firestore
      .collection(collection)
      .limit(limit)
      .onSnapshot((snapshot) => {
        const documents = [];

        snapshot.forEach((doc) => {
          var Id = doc.id;
          for (const key in doc.data()) {
            documents.push([key, doc.data()[key], Id]);
          }
        });
        callback(documents);
        console.log(JSON.stringify(documents));
      });
  },
  document: (collection, documentID, callback) => {
    firestore
      .collection(collection)
      .doc(documentID)
      .onSnapshot((snapshot) => {
        console.log("test  ", snapshot.data());
        callback({ id: snapshot.id, data: snapshot.data() });
      });
  },
  documentAsync: async (collection, documentID) => {
    var docSnapshot = firestore.collection(collection).doc(documentID);
    var documentData = await docSnapshot.get();

    return { id: documentData.id, data: documentData.data() };
  },
  singleDocument: (collection, documentID, callback) => {
    // var _data = null
    // var _id = null
    firestore
      .collection(collection)
      .doc(documentID)
      .onSnapshot((snapshot) => {
        // _data = snapshot.data()
        // _id = snapshot.id
        console.log("test  2 ", snapshot.data());
        callback({ id: snapshot.id, data: snapshot.data() });
        // callback({ id: _id, data: _data });
      });
  },
  documentWithRes: (collection, documentID, callback) => {
    firestore
      .collection(collection)
      .doc(documentID)
      .onSnapshot((snapshot) => {
        callback({ id: snapshot.id, data: snapshot.data() }, true);
      });
  },
  save: (collection, documentData) => {
    firestore
      .collection(collection)
      .add(documentData)
      .then(function (docref) {
        message = {
          id: docref.id,
          message: "document added",
          type: ErrorType.success,
        };
      })
      .catch(function (error) {
        message = { message: error, type: ErrorType.error };
      });
    return message;
  },
  saveCallback: (collection, documentData, callback) => {
    firestore
      .collection(collection)
      .add(documentData)
      .then(function (docref) {
        callback(docref.id, {
          id: docref.id,
          message: "document added",
          type: ErrorType.success,
        });
      })
      .catch(function (error) {
        callback(null, { message: error, type: ErrorType.error });
      });
    return message;
  },
  saveWithId: (collection, documentID, documentData) => {
    firestore
      .doc(`${collection}/${documentID}`)
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          docSnapshot.ref
            .update(documentData)
            .then(function () {
              message = { message: "document update", type: ErrorType.success };
            })
            .catch(function (error) {
              message = { message: error, type: ErrorType.error };
            });
        } else {
          docSnapshot.ref
            .set(documentData, { merge: true })
            .then(function () {
              message = { message: "document update", type: ErrorType.success };
            })
            .catch(function (error) {
              message = { message: error, type: ErrorType.error };
            });
        }
      });

    return message;
  },
  update: (collection, documentID, documentData) => {
    firestore
      .collection(collection)
      .doc(documentID)
      .update(documentData)
      .then(function () {
        message = { message: "document update", type: ErrorType.success };
      })
      .catch(function (error) {
        message = { message: error, type: ErrorType.error };
      });
    return message;
  },
  upload: (directory, file, onStateChange, onError, onComplete) => {
    const uploadTask = FirebaseStorage.ref(`/${directory}/${file.name}`).put(
      file
    );
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        onStateChange(snapshot);
      },
      (error) => {
        onError(error);
      },
      () => {
        FirebaseStorage.ref(directory)
          .child(file.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            onComplete(fireBaseUrl);
          });
      }
    );
    return message;
  },
  deleteKey: (collection, documentID, key) => {
    var obj = {};
    obj[key] = firebase.firestore.FieldValue.delete();
    firestore
      .doc(`${collection}/${documentID}`)
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          docSnapshot.ref.update(obj);
        }
      });
  },
  delete: (collection, documentID) => {
    firestore
      .collection(collection)
      .doc(documentID)
      .delete()
      .then(function () {
        message = { message: "document deleted", type: ErrorType.success };
      })
      .catch(function (error) {
        message = { message: error, type: ErrorType.error };
      });
    return message;
  },
};

// Firestore.saveWithId("winners", "YSXOno9s3dCs7o9VRxUg", {
//     gDO1dF5PwSeDXZjd8MAo: {
//       campaignId: "uXZiAxM7EU4ocmfXi87T",
//       status: false,
//       date: { seconds: 1606590000, nanoseconds: 0 },
//       email: "email@mail.com",
//       lastName: "Hussain",
//       firstName: "Mushahid",
//       contactNumber: "923169003246",
//       coupon: "ZTE3-ZH83-LKJ3",
//       userId: "gWO1dF5PwSeDXZjd8MAo",
//     },
//     gXO1dF5PwSeDXZjd8MAo: {
//       date: { seconds: 1606590000, nanoseconds: 0 },
//       coupon: "ZTE3-ZH83-LKJ3",
//       campaignId: "uXZiAxM7EU4ocmfXi87T",
//       email: "email@mail.com",
//       contactNumber: "923169003246",
//       lastName: "G",
//       status: false,
//       userId: "gWO1dF5PwSeDXZjd8MAo",
//       firstName: "Shah",
//     },
//     gBO1dF5PwSeDXZjd8MAo: {
//       contactNumber: "923169003246",
//       userId: "gWO1dF5PwSeDXZjd8MAo",
//       status: false,
//       email: "email@mail.com",
//       firstName: "Mujahid",
//       coupon: "ZTE3-ZH83-LKJ3",
//       date: { seconds: 1606590000, nanoseconds: 0 },
//       lastName: "Hussain",
//       campaignId: "uXZiAxM7EU4ocmfXi87T",
//     },
//   });
