import ErrorType from "../../../config/errors/error-types";
import { Firestore } from "../../../dao/firebase/firebase-firestore";


var message = {};

const Actions = {

    data: (collection, callback) => {
        return Firestore.data(collection, callback);
    },
    save: (collection, documentData) => { 
        return Firestore.save(collection, documentData);
    },
    saveWithId: (collection, documentID, documentData, field) => {
        return Firestore.saveWithId(collection, documentID, documentData);
    },
    update: (collection, documentID, documentData) => {
        return Firestore.update(collection, documentID, documentData);
    },

    delete: (collection, documentID) => {
        return Firestore.delete(collection, documentID)
    },

    uploadImage: (e, callback) => {
        const image = e.target.files[0];
        try {
            Firestore.upload(image, '', callback);
            message = { message: "file has been upload successfully", type: ErrorType.success }
        } catch (error) {
            console.log(error);
            message = { message: error, type: ErrorType.failure }
        }
        return message;
    },
    showAlert: (msg, setAlertMsg, setShowAlert, time) => {
        setAlertMsg(msg)
        setShowAlert(true)
        setTimeout(() => {
            setAlertMsg("")
            setShowAlert(false)
        }, time);
    },
    validate: (field) => {
        const validate = ((field === null || field === "" || field === undefined || field === "NaN") ? false : true)
        return validate
    }

}

export default Actions;