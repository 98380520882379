import React, { Component } from "react";
export const Heading = (props) => {
  const heading = `h${props.level}`;
  return React.createElement(
    heading,
    {
      style: props.style,
    },
    props.children
  );
};
