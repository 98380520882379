import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Actions from "../actions/form-actions";
import "./stylesheet/campaign.css";
import Form from "react-bootstrap/Form";
const LinksPage = () => {
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [links, setLinks] = useState({});
  const facebookRef = useRef();
  const instagramRef = useRef();
  const twitterRef = useRef();
  const linkedInRef = useRef();
  const youtubeRef = useRef();
  const tiktokRef = useRef();
  const playStoreRef = useRef();
  const appStoreRef = useRef();

  useEffect(() => {
    try {
      Actions.data("links", function (documents) {
        var obj = {};
        for (var key in documents) {
          obj = documents[key];
        }
        delete obj.id;
        console.log(`links data`, obj);
        setTimeout(() => {
          facebookRef.current.value = obj["facebook"];
          instagramRef.current.value = obj["instagram"];
          twitterRef.current.value = obj["twitter"];
          linkedInRef.current.value = obj["linkedin"];
          youtubeRef.current.value = obj["youtube"];
          tiktokRef.current.value = obj["tiktok"];
          playStoreRef.current.value = obj["playstore"];
          appStoreRef.current.value = obj["appstore"];
        }, 1500);
        setLinks(obj);
      });
    } catch (error) {}
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    try {
      const message = Actions.saveWithId("links", "KYqFra4FwpAfBIq0JtLW", {
        facebook: facebookRef.current.value,
        instagram: instagramRef.current.value,
        twitter: twitterRef.current.value,
        linkedin: linkedInRef.current.value,
        youtube: youtubeRef.current.value,
        tiktok: tiktokRef.current.value,
        playstore: playStoreRef.current.value,
        appstore: appStoreRef.current.value,
      });
      console.log(`linkes saved message`, message);
    } catch (error) {}
  };

  const FormFeedback = (props) => {
    return (
      <Form.Control.Feedback type="invalid">
        {props.message}
      </Form.Control.Feedback>
    );
  };

  return (
    <>
      {alert ? (
        <div
          style={{ backgroundColor: "#e14eca", width: "50%" }}
          className="alert alert-danger d-flex align-self-center justify-content-center  "
          role="alert"
        >
          <strong>{alertMsg} </strong>
        </div>
      ) : (
        ""
      )}
      <Row>
        <Card>
          <Card.Body>
            <>
              <Card.Title className="d-flex justify-content-center">
                <h4 className={""}>Social Links</h4>
              </Card.Title>
              <>
                <Form noValidate>
                  {/* gernal info */}

                  <Form.Row className="d-flex justify-content-center">
                    <Form.Group as={Col} md={6}>
                      <Form.Label className={""}>Facebook</Form.Label>
                      <Form.Control
                        key={Math.random()}
                        ref={facebookRef}
                        type={"text"}
                        name={"facebook"}
                        placeholder={"EBigWin Facebook Profile/Page"}
                      />
                      <FormFeedback
                        message={"Please provide Facebook Profile/Page"}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                      <Form.Label className={""}>Instagram</Form.Label>
                      <Form.Control
                        key={Math.random()}
                        ref={instagramRef}
                        type={"text"}
                        name={"instagram"}
                        placeholder={"EBigWin Instagram Profile"}
                      />
                      <FormFeedback
                        message={"Please provide Instagram Profile"}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label className={""}>Twitter</Form.Label>
                      <Form.Control
                        key={Math.random()}
                        ref={twitterRef}
                        type={"text"}
                        name={"twitter"}
                        placeholder={"EBigWin Twitter Profile"}
                      />
                      <FormFeedback
                        message={"Please provide Twitter Profile"}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                      <Form.Label className={""}>LinkedIn</Form.Label>
                      <Form.Control
                        key={Math.random()}
                        ref={linkedInRef}
                        type={"text"}
                        name={"linkedIn"}
                        placeholder={"EBigWin LinkedIn Profile"}
                      />
                      <FormFeedback
                        message={"Please provide LinkedIn Profile"}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label className={""}>YouTube</Form.Label>
                      <Form.Control
                        key={Math.random()}
                        ref={youtubeRef}
                        type={"text"}
                        name={"youtube"}
                        placeholder={"EBigWin YouTube Channel Link"}
                      />
                      <FormFeedback
                        message={"Please provide YouTube Channel Link"}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label className={""}>TikTok</Form.Label>
                      <Form.Control
                        key={Math.random()}
                        ref={tiktokRef}
                        type={"text"}
                        name={"tiktok"}
                        placeholder={"EBigWin TikTok Profile"}
                      />
                      <FormFeedback message={"Please provide TikTok Profile"} />
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                      <Form.Label className={""}>Google Play Store</Form.Label>
                      <Form.Control
                        key={Math.random()}
                        ref={playStoreRef}
                        type={"text"}
                        name={"playstore"}
                        placeholder={"EBigWin Google Play Store App Link"}
                      />
                      <FormFeedback
                        message={"Please provide Google Play Store App Link"}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                      <Form.Label className={""}>App Store</Form.Label>
                      <Form.Control
                        key={Math.random()}
                        ref={appStoreRef}
                        type={"text"}
                        name={"appstore"}
                        placeholder={"EBigWin App Store App Link"}
                      />
                      <FormFeedback
                        message={"Please provide App Store App Link"}
                      />
                    </Form.Group>
                  </Form.Row>
                </Form>
                <div className="d-flex justify-content-center">
                  <Button onClick={handleSave}>{"Update"}</Button>
                </div>
              </>
            </>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default LinksPage;
