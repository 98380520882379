import React from "react";
import { Badge, Button } from "react-bootstrap";
import { EditType } from "../../../../config/forms/form"; 
import generateHash from "../../../../helpers/hash";
import Flex, { FlexProps } from "../../../flex-box/flex";

const PageCard = (props) => {
  const page = props.data;
  const onEdit = props.onEdit;
  const onDelete = props.onDelete;

  return (
    <div key={generateHash()} className="page-card m-4">
      <div className="page-header">
        {page?.active != null || page?.active ? (
          <Badge className={"mb-2"} variant={"danger"}>
            Active
          </Badge>
        ) : (
          <></>
        )}
        <h6>{page?.title}</h6>
      </div>

      <div className="page">
        <Flex className="page-info" direction={"column"}>
          <div style={{ flex: 1 }}>
            <div
              className="prize-description"
              dangerouslySetInnerHTML={{
                __html: page?.content.substr(0, 120),
              }}
            ></div>
          </div>

          <Flex justifyContent={FlexProps.justifyContent.spaceBetween}>
            <Button
              size={"sm"}
              onClick={() => {
                onEdit(page, EditType.edit);
                window.scroll(0, 0);
              }}
            >
              Edit
            </Button>
            <Button
              size={"sm"}
              onClick={() => {
                onDelete(page);
              }}
            >
              Delete
            </Button>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

export default PageCard;
