import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EditType } from "../../../../config/forms/form";
import "./smart-editor.css";
const SmartEditor = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => { 
    if (props.editType === EditType.edit) {
      setValue(props.html);
    }
  }, [props.html, props.editType]);
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={(html) => {
        console.log(html);
        setValue(html);
        props.onChange(html);
      }}
    />
  );
};
export default SmartEditor;
