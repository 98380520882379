import React, { useState } from "react";
import { Auth } from "../dao/auth/auth";
export const firebaseAuth = React.createContext();

const AuthProvider = (props) => {
  const [inputs, setInputs] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState([]);
  const [token, setToken] = useState(null);
  const handleSignup = (email, password, callback) => {
    console.log("this is inputs" + email, password);
    return Auth.signup(email, password, setErrors, setToken, function (bool) {
      callback(bool);
    });
  };
  const handleSignin = () => {
    return Auth.signin(inputs.email, inputs.password, setErrors, setToken);
  };
  const handleSignout = () => {
    Auth.signout(setErrors, setToken);
  };
  return (
    <firebaseAuth.Provider
      value={{
        handleSignin,
        handleSignup,
        handleSignout,
        inputs,
        setInputs,
        errors,
        setToken,
        token,
      }}
    >
      {props.children}
    </firebaseAuth.Provider>
  );
};
export default AuthProvider;
