import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import generateHash from "../../../helpers/hash";

import Flex, { FlexProps } from "../../flex-box/flex";
import pagesModel from "../../../models/page-model";
import Dialog from "../../dialog/dialog";
import { EditType } from "../../../config/forms/form";
import Actions from "../actions/form-actions";
import "./stylesheet/pages.css";
import { useParams } from "react-router";
import { Firestore } from "../../../dao/firebase/firebase-firestore";
import PageFilters from "./components/filter";
import PageForm from "./components/form";
import PageCard from "./components/card";
const Page = () => {
  const [filteredPages, setFilteredPages] = useState([]);
  const [pages, setPages] = useState([]);
  const [formEditState, setEditForm] = useState(false);
  const [formEditType, setFormEditType] = useState(EditType.create);
  const [activePage, setActivePage] = useState(pagesModel);
  const [showDialog, setShowDialog] = useState(false);
  let parm = useParams();
  useEffect(() => {
    try {
      Firestore.data("pages", function (documents) {
        setPages(documents);
      });
    } catch (error) {}
  }, []);

  const insertPageData = (page) => {
    const temp = pages;
    temp.push(page);
    setPages(temp);
    Actions.save("pages", page);
  };

  const updatePagesData = (pageId, page) => {
    const temp = pages.filter((v, k) => v.id !== pageId);
    temp.push(page);
    setPages(temp);
    Actions.update("pages", pageId, page);
  };

  const onFormEdit = (page, editType) => {
    setEditForm(true);
    setFormEditType(editType);
    setActivePage(page);
  };
  const afterFormSubmit = (page, valitdateStatus, editType) => {
    if (valitdateStatus) {
      if (page.status === "") {
        if (page.soldCount === "") page.soldCount = "0";
        page.status = "active";
      }
      switch (editType) {
        case EditType.create:
          insertPageData(page);
          break;
        case EditType.edit:
          updatePagesData(page.id, page);
          break;
        case EditType.delete:
          break;
        default:
          break;
      }
      setEditForm(false);
    } else {
      setEditForm(true);
    }
  };
  const onDeletePress = (page) => {
    console.log(page)
    setActivePage(page);
    setShowDialog(true);
    console.log(showDialog);
  };
  const onDeleteYes = () => {
    const afterDeletepages = pages.filter(
      (page, k) => page.id !== activePage.id
    );

    const message = Firestore.delete("pages", activePage.id);
    console.log(message);

    setPages(afterDeletepages);
    setShowDialog(false);
  };

  const onDialogClose = () => {
    setShowDialog(false);
    console.log(showDialog);
  };

  const FilteredCards = () => {
    return (
      <div className="cards-container m-4">
        {filteredPages.length > 0
          ? filteredPages.map((pages) => (
              <PageCard
                key={generateHash()}
                data={pages}
                onEdit={onFormEdit}
                onDelete={onDeletePress}
              />
            ))
          : pages
              .filter((item) =>
                parm.status === "completed"
                  ? item.status === parm.status
                  : parm.status === "finished"
                  ? item.status === parm.status
                  : item.status === "completed" ||
                    "finished" ||
                    "active" ||
                    "inactive"
              )
              .map((pages) => (
                <PageCard
                  onEdit={onFormEdit}
                  key={generateHash()}
                  data={pages}
                  onDelete={onDeletePress}
                />
              ))}
      </div>
    );
  };

  return (
    <>
      {showDialog ? (
        <Dialog
          title={"Delete pages"}
          show={showDialog}
          onClose={onDialogClose}
        >
          <Flex justifyContent={FlexProps.justifyContent.spaceBetween}>
            <Button size={"sm"} className={""} onClick={onDeleteYes}>
              {"Yes"}
            </Button>
            <Button size={"sm"} className={""} onClick={onDialogClose}>
              {"Cancel"}
            </Button>
          </Flex>
        </Dialog>
      ) : (
        <></>
      )}
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Flex justifyContent={FlexProps.justifyContent.flexEnd}>
                <Button
                  size={"sm"}
                  onClick={() => {
                    setActivePage(pagesModel);
                    setFormEditType(EditType.create);
                    setEditForm(!formEditState);
                  }}
                >
                  {formEditState ? "Cancel" : "Create pages"}
                </Button>
              </Flex>
              {formEditState ? (
                <PageForm
                  editType={formEditType}
                  data={activePage}
                  onSubmit={afterFormSubmit}
                />
              ) : (
                <div>
                  <PageFilters
                    onSearch={(pagesName, pagestatus) => {
                      let _filteredPages = pages.filter(
                        (v, k) =>
                          v.title === pagesName || v.status === pagestatus
                      );

                      if (_filteredPages.length === 0) {
                        alert("no match found");
                      }
                      setFilteredPages(_filteredPages);
                      console.log(pagesName, pagestatus, _filteredPages);
                    }}
                  />
                  {filteredPages.length === 0 && pages.length === 0 ? (
                    <p>Loading...</p>
                  ) : (
                    <FilteredCards />
                  )}
                </div>
              )}
              {/* Article List Ended */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Page;
