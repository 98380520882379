import React, { useState } from "react";
const WinnerCard = (props) => {
  console.log(props.data)
  const winner = props.data;
  return (
    <div className="campaign-card">
      <div className="campaign-header">
        <h6> {winner.firstName} {winner.lastName}{" "}</h6>
      </div>
      <div className="campaign" style={{ flexWrap: "wrap" }}>
        <div className="campaign-preview">
          <h6>{winner.campaign}</h6>
          <h2>Winner</h2>
          <h6>{winner.prize}</h6>
        </div>
        <div className="campaign-info">
          <h6 className="">{winner.coupon}</h6> 
          <h5>{winner.email}</h5>
          <h5>{winner.contactNumber}</h5>
          <h6>{winner.status}</h6>
        </div>
      </div>
    </div>
  );
};
export default WinnerCard;
