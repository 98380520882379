import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Actions from "../actions/form-actions";
import "./stylesheet/campaign.css";
import Form from "react-bootstrap/Form";
import { Firestore } from "../../../dao/firebase/firebase-firestore";
import Flex, { FlexProps } from "../../flex-box/flex";
import { ProgressBar } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Dialog from "../../dialog/dialog";
import Modal from 'react-bootstrap/Modal' 
import { generateCoupon } from "../../../helpers/hash";
const FakeWinner = () => {
  const [alert, setalert] = useState(false);
  const [alertMsg, setalertMsg] = useState("");
  const [winnersUI, setWinnersUI] = useState([]);
  const [winners, setWinners] = useState({});
  const [fileProgress, setFileProgress] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [winnerId, setWinnerId] = useState("");
  const [showDeletePopUp, setshowDeletePopUp] = useState(false);

  const tableheader = [
    { header: "S:NO" },
    { header: "Name" },
    { header: "Campaign" },
    { header: "Prize" },
    { header: "Date" },
    { header: "Coupon" },
    { header: "Action" },
  ];
  useEffect(() => {
    try {
      Actions.data("fakewinners", function (documents) {
        setWinnersUI(documents);
        console.log("this is fakewinners ", JSON.stringify(documents));
      });
    } catch (error) {}
  }, []);

  const handleSave = (e) => {
    
    try {
      if (
        Actions.validate(winners.image) &&
        Actions.validate(winners.campaign) &&
        Actions.validate(winners.date) &&
        Actions.validate(winners.prize) &&
        Actions.validate(winners.name)
      ) {
        console.log("colors code : ", JSON.stringify(winners));
        var temp = winners; 
        temp.coupon = generateCoupon();

        Actions.save("fakewinners", temp);
        Actions.showAlert(
          "Information has been saved",
          setalertMsg,
          setalert,
          "2000"
        );
setWinnersUI([...winnersUI,temp])
        setWinners({}); 
        
      } else {
        Actions.showAlert(
          "Please  fill all  fields",
          setalertMsg,
          setalert,
          "2000"
        );
      }
    } catch (error) {}
    
  };

  const handleChange = (e) => {
    setWinners({
      ...winners,
      [e.target.name]: e.target.value,
    });
    console.log("this is my tem winner ", JSON.stringify(winners));
  };

  const handleUpload = (event) => {
    setShowDialog(true);
    var file = event.target.files[0];
    const name = event.target.name;
    Firestore.upload(
      "campaigns",
      file,
      (snapshot) => {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (fileProgress < 100) {
          setFileProgress(percentage);
        }
      },
      (error) => {
        setShowDialog(false);
      },
      (fileURL) => {
        var tempWinner = winners;
        tempWinner.image = fileURL;
        setWinners(tempWinner);
        setShowDialog(false);
        setFileProgress(0);
      }
    );
  };

  const FormFeedback = (props) => {
    return (
      <Form.Control.Feedback type="invalid">
        {props.message}
      </Form.Control.Feedback>
    );
  };

   /*  deleting the item on onclick event */

   const handleDelete = () => {
     Actions.delete("fakewinners", winnerId)  
    winnersUI.forEach((winner,index)=> {
      if(winner.id == winnerId) {
        winnersUI.splice(index,1)
      }
    })
    setshowDeletePopUp(false)
    window.scrollTo(0, 0)
    Actions.showAlert(
      "Information has been deleted ",
      setalertMsg,
      setalert,
      "2000"
    ); 
}
  return (
    <>
      {showDialog ? (
        <Dialog title={"Image Uploading"} show={showDialog}>
          <Flex
            justifyContent={FlexProps.justifyContent.center}
            alignItems={FlexProps.alignItems.center}
          >
            <ProgressBar
              variant="success"
              min={0}
              max={100}
              now={fileProgress}
            />
          </Flex>
        </Dialog>
      ) : (
        <></>
      )}

      {alert ? (
        <div
          style={{ backgroundColor: "#e14eca", width: "50%" }}
          className="alert alert-danger d-flex align-self-center justify-content-center  "
          role="alert"
        >
          <strong>{alertMsg} </strong>
        </div>
      ) : (
        ""
      )}
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              {/* <Col className="d-flex justify-content-end" lg={"12"}>
                {" "}
                <Button>{"Add Winner"}</Button>{" "}
              </Col> */}
              <>
                <Card.Title className="d-flex justify-content-center">
                  <h2 className={""}> Create Winner </h2>
                </Card.Title>
                <>
                  <Form  noValidate>
                    {/* gernal info */}
                    <Form.Row className="d-flex justify-content-center">
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}> Campaign Name</Form.Label>
                        <Form.Control
                          value={winners.campaign}
                          onChange={handleChange}
                          type={"text"}
                          name={"campaign"}
                          placeholder={"Enter campaign Name"}
                        />
                        <FormFeedback
                          message={"Please provide  campaign Name."}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}> Prize Name</Form.Label>
                        <Form.Control
                          value={winners.prize}
                          onChange={handleChange}
                          type={"text"}
                          name={"prize"}
                          placeholder={"Enter prize Name"}
                        />
                        <FormFeedback message={"Please provide  prize Name."} />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="d-flex justify-content-center">
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}> Winner Name</Form.Label>
                        <Form.Control
                          value={winners.name}
                          onChange={handleChange}
                          type={"text"}
                          name={"name"}
                          placeholder={"Enter Winner Name"}
                        />
                        <FormFeedback
                          message={"Please provide  Winner Name."}
                        />
                      </Form.Group>
                      <Form.Group
                        className="d-flex flex-column  mt-0"
                        as={Col}
                        md={4}
                      >
                        
                        <Form.Label className={"block"}> Announced Date </Form.Label>
                        <Form.Control
                          value={winners.date}
                          onChange={handleChange}
                          type={"date"}
                          name={"date"} 
                        />
                        <FormFeedback
                          message={"Please provide a valid date"}
                        />
                      </Form.Group>
                      <Form.Group
                        className="d-flex justify-content-center mt-4"
                        as={Col}
                        md={2}
                      >
                        
                        <Form.Label    style={{ width: " 100%" }}  className={"fileUploadLabel"}>
                          Upload Prize Image
                        </Form.Label>
                        <Form.Control
                          onChange={handleUpload}
                          type={"file"}
                          accept={".gif,.jpg,.jpeg,.png"}
                          name={"image"}
                        />
                        <FormFeedback
                          message={"Please provide a valid Prize image."}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Form>
                  <div className="d-flex justify-content-center">
                    <Button onClick={handleSave}>{"Save"}</Button>
                  </div>
                </>
              </>
              <>
                <div className="table-responsive">
                  <Table className="table tablesorter " id="">
                    <thead className=" text-primary">
                      <tr>
                        {tableheader.map((item) => (
                          <th className="text-center" key={Math.random()}>
                            {item.header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {winnersUI.map((item, index) => (
                        <tr key={Math.random()}>
                          <td className="text-center">{index}</td>
                          <td className="text-center">{item.name}</td>
                          <td className="text-center">{item.campaign}</td>
                          <td className="text-center">{item.prize}</td>
                          <td className="text-center">{item.date}</td>
                          <td className="text-center">{item.coupon}</td>
                          <td className="text-center">
                            <Button
                              onClick={() => {
                                setshowDeletePopUp(true);
                                setWinnerId(item.id);
                              }}
                              className="btn btn-icon btn-round"
                            >
                              {" "}
                              <i
                                className={"tim-icons icon-simple-remove "}
                              ></i>
                            </Button>{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </>
            </Card.Body>
          </Card>
        </Col>
        <Modal
                size="sm"
                show={showDeletePopUp}
                onHide={() => setshowDeletePopUp(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                variant="warning"

            >
                <Modal.Header className="darkbg" closeButton>
                    <Modal.Title className="white-color  font-large" id="example-modal-sizes-title-lg">
                        Delete
                        
                        </Modal.Title>
                </Modal.Header >
                <Modal.Body className="d-flex justify-content-center align-item-center flex-column darkbg">
                    <div className="d-flex justify-content-center align-item-center">
                        <p className="white-color  font-large" >Are You Sure ?</p>
                    </div>
                    <div className="d-flex x justify-content-center align-item-center flex-row">
                        <Button type="submit" className="btn btn-icon btn-round medium-button  " onClick={handleDelete} >   Yes</Button>{' '}
                        <Button type="submit" className="btn btn-icon btn-round  medium-button " onClick={() => setshowDeletePopUp(false)}>   No</Button>{' '}
                    </div>
                </Modal.Body>
            </Modal>
      </Row>
    </>
  );
};

export default FakeWinner;
