import { stringify } from "querystring";
import axios from "axios";
const sendEmail = (email, subject, template) => {
  console.log(`sending email`, {
    email: email,
    subject: subject,
    template: template,
  });
  var data = stringify({
    email: email,
    subject: subject,
    template: template,
  });
  var config = {
    method: "post",
    url: "https://ebigwin.herokuapp.com/email/sendEmail",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default sendEmail;
