import React from "react";
import { Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CampaignsConfig } from "../../../../config/campaigns/campaigns-config";
import {   EditType } from "../../../../config/forms/form";
import { Heading } from "../../../../dom/dom";
import generateHash from "../../../../helpers/hash"; 
import Flex, { FlexProps } from "../../../flex-box/flex";

const CampaignCard = (props) => {
  const campaign = props.data;
  const onEdit = props.onEdit;
  const onDelete = props.onDelete;

  return (
    <div key={generateHash()} className="campaign-card">
      <div className="campaign-header">
        { campaign?.flash != null && campaign?.flash ? <Badge className={"mb-2"} variant={"danger"}>Flash</Badge>: <Badge   className={"mb-2 bg-transparent"} variant={"danger"}> UnFlash </Badge>}
        <h6>{campaign?.title}</h6>

      </div>

      <div className="campaign">
        <div className="campaign-preview">
          <h6>Open Offer </h6>
          <div className="uppercard-heading mt-3">
            <Heading level={3}>{campaign?.product?.title}</Heading>
            <Heading level={3}>{campaign?.product?.price}</Heading>
          </div>
          {campaign?.product !== undefined ? (
            <img
              className="product-image mt-3"
              src={campaign?.product?.image}
              alt="product"
            />
          ) : (
            <></>
          )}

          <h5 className="mt-4 product-description">{campaign?.product?.description.substr(0, 120)}</h5>
        </div>

        <Flex className="campaign-info" direction={"column"}>
          <div style={{ flex: 1 }}>
            <div className="progress-container">
              <div className="progress" style={{ boxShadow: "unset" }}></div>
              <span className="progress-text">
                {campaign?.soldCount}/{campaign?.threshold} Soldout
              </span>
            </div>
            <h6 className="text-dark">Winning Prize</h6>
            <h2 className="text-dark">{campaign?.prize?.title}</h2>
            {campaign?.prize !== undefined ? (
              <img
                className="prize-image mt-3"
                src={campaign?.prize?.image}
                alt="prize"
              />
            ) : (
              <></>
            )} 
            <div className="prize-description">
              <h5 className="text-dark reward-description mt-2">{campaign?.prize?.description.substr(0, 120)}</h5>
            </div>
          </div>
          <Flex justifyContent={FlexProps.justifyContent.spaceBetween}>
            {campaign?.status === CampaignsConfig.status.completed ||
            campaign?.status === CampaignsConfig.status.finished ? (
              campaign?.status === CampaignsConfig.status.finished ? (
                <Link
                style={{
                  height: "fit-content",
                  margin: "4px 1px",
                }}
                  className={"btn-primary btn-sm"}
                  to={{
                    pathname: "/winners",
                    campaign: campaign,
                  }}
                >
                  {""} Get Winners {""}
                </Link>
              ) : (
                <Link
                style={{
                  height: "fit-content",
                  margin: "4px 1px",
                }}
                  className={"btn-primary btn-sm"}
                  to={{
                    pathname: "/luckydraw",
                    campaign: campaign,
                  }}
                >
                  {""} Lucky Draw {""}
                </Link>
              )
            ) : (
              <></>
            )}

            <Button
              size={"sm"}
              onClick={() => {
                onEdit(campaign, EditType.edit);
                window.scroll(0, 0);
              }}
            >
              Edit
            </Button>
            <Button
              size={"sm"}
              onClick={() => {
                onDelete(campaign);
              }}
            >
              Delete
            </Button>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

export default CampaignCard;
