import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  ProgressBar,
} from "react-bootstrap";
import { EditType } from "../../../../config/forms/form";
import { Firestore } from "../../../../dao/firebase/firebase-firestore";
import generateHash from "../../../../helpers/hash";
import CampaignModel from "../../../../models/campaign-model";
import Validate from "../../../../validation/validate";
import Dialog from "../../../dialog/dialog";
import Flex, { FlexProps } from "../../../flex-box/flex";
import SmartEditor from "./smart-editor";

const FormFeedback = (props) => {
  return (
    <Form.Control.Feedback type="invalid">
      {props.message}
    </Form.Control.Feedback>
  );
};
class PageForm extends Component {
  initState() {
    
    this.state.page = this.props.data;
  }
  constructor(props) {
    super(props);
    this.state = {
      editType: this.props.editType,
      page: this.props.editType === "edit" ? this.props.data : CampaignModel,
      validated: false,
      showDialog: false,
      fileProgress: 0,
    };
    this.setFormData = this.setFormData.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }
  // const [pageData, setPagesData] = useState(PagesModel);
  // const [validated, setValidated] = useState(false);
  // setPagesData(props.data);

  uploadFile(event) {
    this.setState({ showDialog: true });

    var file = event.target.files[0];
    const name = event.target.name;

    Firestore.upload(
      "page",
      file,
      (snapshot) => {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        if (this.state.fileProgress < 100) {
          this.setState({ fileProgress: percentage });
        }
      },
      (error) => {
        this.setState({ showDialog: false });
      },
      (fileURL) => {
        const tempPages = this.state.page;
        if (name === "product.image") {
          tempPages.product.image = fileURL;
          this.setState({ page: tempPages });
        } else if (name === "prize.image") {
          tempPages.prize.image = fileURL;
          this.setState({ page: tempPages });
        }
        this.setState({ showDialog: false });
        this.setState({ fileProgress: 0 });
      }
    );
  }

  setFormData(event) {
    const tempData = this.state.page;
    tempData[event.target.name] = event.target.value;
    this.setState({ page: tempData });
  }

  setContent(html) {
    const tempData = this.state.page;
    tempData["content"] = html;
    this.setState({ page: tempData });
    console.log(this.state.page, html);
  }

  // const temp = PagesActions.setFormData(e, pageData);
  // setPagesData(temp);

  // ABOVE methods contain bugs

  onSubmit = (e) => {
    e.preventDefault();
    const validateStatus = Validate.form(e);
    console.log(
      "this is new file here &&&&&&& ",
      JSON.stringify(this.state.page)
    );
    this.props.onSubmit(this.state.page, validateStatus, this.state.editType);
    this.setState({ validated: true });
    this.setState({ campaign: CampaignModel });
    // PagesActions.submitForm(e, this.state.page);
  };

  FormField = (
    props = {
      as: Col,
      md: 4,
      label: "",
      type: "",
      name: "",
      placeholder: "",
      value: "",
      onChange: () => {},
      required: true,
      validateMessage: "",
    }
  ) => {
    return (
      <Form.Group
        controlId={generateHash()}
        key={generateHash()}
        as={props.as}
        md={props.md}
      >
        <Form.Label className={""}>{props.label}</Form.Label>
        {props.required ? (
          <Form.Control
            onChange={props.onChange}
            value={props.value}
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
            required
          />
        ) : (
          <Form.Control
            onChange={props.onChange}
            value={props.value}
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
          />
        )}
        <FormFeedback message={props.validateMessage} />
      </Form.Group>
    );
  };

  render() {
    return (
      <>
        {this.state.showDialog ? (
          <Dialog title={"Image Uploading"} show={this.state.showDialog}>
            <Flex
              justifyContent={FlexProps.justifyContent.center}
              alignItems={FlexProps.alignItems.center}
            >
              <ProgressBar
                variant="success"
                min={0}
                max={100}
                now={this.state.fileProgress}
              />
            </Flex>
          </Dialog>
        ) : (
          <></>
        )}

        <Card.Title className="d-flex justify-content-between">
          <h2 className={""}> Create page </h2>
          {alert ? <Alert message={"Test Alert"} /> : <div></div>}
        </Card.Title>
        <>
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.onSubmit}
          >
            {/* gernal info */}

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className={""}>Pages Title</Form.Label>

                <Form.Control
                  value={this.state.page.title}
                  onChange={this.setFormData}
                  type={"text"}
                  name={"title"}
                  placeholder={"Enter page Title"}
                />
                <FormFeedback message={"Please provide a valid page title."} />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className={""}>{"Select Status"}</Form.Label>
                <Form.Control
                  value={this.state.page.status}
                  onChange={this.setFormData}
                  as={"select"}
                  name={"status"}
                  placeholder={"Select Status"}
                  required
                >
                  <option
                    key={generateHash()}
                    className={"darkbg"}
                    value={"active"}
                  >
                    {"Active"}
                  </option>
                  <option
                    key={generateHash()}
                    className={"darkbg"}
                    value={"inactive"}
                  >
                    {"In Active"}
                  </option>
                  {this.state.page.soldCount > 3 ? (
                    <>
                      <option
                        key={generateHash()}
                        className={"darkbg"}
                        value={"completed"}
                      >
                        {"Complete"}
                      </option>
                      <option
                        key={generateHash()}
                        className={"darkbg"}
                        value={"finished"}
                      >
                        {"Finished"}
                      </option>
                    </>
                  ) : (
                    ""
                  )}
                </Form.Control>
                <FormFeedback message={"Please provide a valid page status."} />
              </Form.Group>
            </Form.Row>
            <Row>
              <h4 className="pl-3 pt-3 "> {"Details"}</h4>
            </Row>

            <Form.Row>
              <Form.Group as={Col} md={8} lg={6} sm={12}>
                <Form.Label className={""}>Product Content</Form.Label>
                <SmartEditor
                  editType={this.state.editType}
                  html={this.state.page.content}
                  onChange={(html) => {
                    this.setContent(html);
                  }}
                />

                <FormFeedback message={"Please provide a valid content."} />
              </Form.Group>
            </Form.Row>

            <Button type="submit"> 
              {this.state.editType === EditType.create ? "Save" : "Update"}
            </Button>
          </Form>
        </>
      </>
    );
  }
}

export default PageForm;
