const CampaignModel = {
    prize: {
        description: "",
        image: "",
        title: ""
    },
    product: {
        description: "",
        image: "",
        price: "",
        title: ""
    },
    soldCount: "",
    threshold: "",
    title: "",
    status: "",
    id: "",
    flash: false
}
export default CampaignModel