import firebase from 'firebase';
import 'firebase/storage'
import 'firebase/auth'
const config = {
     
    apiKey: "AIzaSyC2aY1PlGJ3KyMxGt7JTQ1q6OSSmvItgzY",
    authDomain: "ebigwin-40cb3.firebaseapp.com",
    databaseURL: "https://ebigwin-40cb3.firebaseio.com",
    projectId: "ebigwin-40cb3",
    storageBucket: "ebigwin-40cb3.appspot.com",
    messagingSenderId: "1036426075942",
    appId: "1:1036426075942:web:524a4b76c22726f02eabe7",
    measurementId: "G-EBCB8R7HNG"
};
firebase.initializeApp(config);
const FirebaseStorage = firebase.storage()
firebase.auth()
export {
    FirebaseStorage,
    config,
    firebase as
    default
}
// apiKey: "AIzaSyB0TVFCKIrfT2V3OzDXtnjlxHgRwoaKOeQ",
// authDomain: "ebigwintest.firebaseapp.com",
// projectId: "ebigwintest",
// storageBucket: "ebigwintest.appspot.com",
// messagingSenderId: "183933140714",
// appId: "1:183933140714:web:cb797bfe305312294ce193",
// measurementId: "G-W9SLYH2947"