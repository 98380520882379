import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "./stylesheet/settings.css";
import Form from "react-bootstrap/Form";
import { Firestore } from "../../../dao/firebase/firebase-firestore";
import generateHash from "../../../helpers/hash";
const Settings = () => {
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [settings, setSettings] = useState({ vat: "" });

  const vatRef = useRef();
  const jazzCashRef = useRef();
  const easypaisaRef = useRef();
  const upaisaRef = useRef();

  useEffect(() => {
    try {
      Firestore.data("settings", function (data) {
        delete data[0]["id"];
        setSettings(data[0]);
        vatRef.current.value = data[0]["vat"];
        jazzCashRef.current.value = data[0]["jazzcash"];
        easypaisaRef.current.value = data[0]["easypaisa"];
        upaisaRef.current.value = data[0]["upaisa"];
      });
    } catch (error) {}
  }, []);

  const handleSave = (e) => {
    e.preventDefault();

    try {
      setSettings({
        ...settings,
        vat: vatRef.current.value,
        jazzcash: jazzCashRef.current.value,
        easypaisa: easypaisaRef.current.value,
        upaisa: upaisaRef.current.value,
      });
      var message = Firestore.saveWithId("settings", "settings", {
        vat: vatRef.current.value,
        jazzcash: jazzCashRef.current.value,
        easypaisa: easypaisaRef.current.value,
        upaisa: upaisaRef.current.value,
      });
      console.log(`message`, message);
      setAlertMsg(message.message);
      setAlert(true);
    } catch (error) {}
  };

  const FormFeedback = (props) => {
    return (
      <Form.Control.Feedback type="invalid">
        {props.message}
      </Form.Control.Feedback>
    );
  };

  return (
    <>
      {alert ? (
        <div
          style={{ backgroundColor: "#e14eca", width: "50%" }}
          className="alert alert-danger d-flex align-self-center justify-content-center  "
          role="alert"
        >
          <strong>{alertMsg} </strong>
        </div>
      ) : (
        ""
      )}

      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <>
                <Card.Title className="d-flex justify-content-start">
                  <h2 className={""}> Add Admin </h2>
                </Card.Title>
                <>
                  <Form noValidate>
                    {/* gernal info */}
                    <Form.Row className="d-flex justify-content-start">
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}> VAT NUMBER </Form.Label>
                        <p>
                          <b>Current VAT:</b>
                          <br />
                          <span style={{ fontWeight: "bold" }}>
                            {settings.vat}
                          </span>
                        </p>
                        <Form.Control
                          key={generateHash()}
                          ref={vatRef}
                          type={"text"}
                          name={"vat"}
                          placeholder={"Enter VAT"}
                        />
                        <FormFeedback message={"Please provide VAT"} />
                      </Form.Group>
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}> Jazz Cash </Form.Label>
                        <p>
                          <b>Current JazzCash Account Number:</b>
                          <br />
                          <span style={{ fontWeight: "bold" }}>
                            {settings.jazzcash}
                          </span>
                        </p>
                        <Form.Control
                          key={generateHash()}
                          ref={jazzCashRef}
                          type={"text"}
                          name={"jazzcash"}
                          placeholder={"Enter JazzCash Account Number"}
                        />
                        <FormFeedback
                          message={"Please provide JazzCash Account Number"}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}> Easypaisa </Form.Label>
                        <p>
                          <b>Current Easypaisa Account Number:</b>
                          <br />
                          <span style={{ fontWeight: "bold" }}>
                            {settings.easypaisa}
                          </span>
                        </p>
                        <Form.Control
                          key={generateHash()}
                          ref={easypaisaRef}
                          type={"text"}
                          name={"easypaisa"}
                          placeholder={"Enter Easypaisa Account Number"}
                        />
                        <FormFeedback
                          message={"Please provide Easypaisa Account Number"}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}> Upaisa </Form.Label>
                        <p>
                          <b>Current Upaisa Account Number:</b>
                          <br />
                          <span style={{ fontWeight: "bold" }}>
                            {settings.upaisa}
                          </span>
                        </p>
                        <Form.Control
                          key={generateHash()}
                          ref={upaisaRef}
                          type={"text"}
                          name={"upaisa"}
                          placeholder={"Enter Upaisa Account Number"}
                        />
                        <FormFeedback
                          message={"Please provide Upaisa Account Number"}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Form>
                  <div className="d-flex justify-content-center">
                    <Button onClick={handleSave}>Update Settings</Button>
                  </div>
                </>
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
