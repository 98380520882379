import React, { Component, useState } from "react";
import generateHash from "../../../../helpers/hash";
const { Col, Form, Button } = require("react-bootstrap");

class PageFilters extends Component {
  constructor(props) {
    super(props);
    this.state = { pagesName: "", pagesStatus: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSearch() {
    this.props.onSearch(this.state.pagesName, this.state.pagesStatus);
  }

  render() {
    return (
      <Col md={12} className="mt-5">
        <span className="mr-4 font-small "> Search pages Which are : </span>
        <span style={{ display: "unset" }} className="custom-control ">
          <Form.Row inline={"true"} className="align-items-center">
            <Form.Group as={Col} md={4}>
              <Form.Control
                value={this.state.pagesName}
                onChange={this.handleChange}
                type={"text"}
                name={"pagesName"}
                placeholder="Search"
              />
            </Form.Group>

            <Form.Group
              controlId={generateHash()}
              key={generateHash()}
              as={Col}
              md={4}
            >
              <Form.Control
                value={this.state.pagesStatus}
                onChange={this.handleChange}
                as={"select"}
                name={"pagesStatus"}
                placeholder={"Select Status"}
                required
              >
                <option key={generateHash()} className={"darkbg"}>
                  {"Select Status"}
                </option>
                <option
                  key={generateHash()}
                  className={"darkbg"}
                  value={"active"}
                >
                  {"Active"}
                </option>
                <option
                  key={generateHash()}
                  className={"darkbg"}
                  value={"inactive"}
                >
                  {"In Active"}
                </option>
                <option
                  key={generateHash()}
                  className={"darkbg"}
                  value={"completed"}
                >
                  {"Complete"}
                </option>
                <option
                  key={generateHash()}
                  className={"darkbg"}
                  value={"finished"}
                >
                  {"Finished"}
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              controlId={generateHash()}
              key={generateHash()}
              as={Col}
              md={2}
            >
              <Button size={"sm"} onClick={this.handleSearch}>
                Search
              </Button>
            </Form.Group>
          </Form.Row>
        </span>
      </Col>
    );
  }
}

export default PageFilters;
