import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import generateHash from "../../helpers/hash";

const Dialog = (
  props = {
    title: "",
    show: false,
    onClose: () => {},
  }
) => {
  const [smShow, setSmShow] = useState(props.show);
  const onClose = props.onClose;

  const modal_id = generateHash();
  return (
    <>
      <Modal
        size="sm"
        show={smShow}
        onHide={onClose}
        aria-labelledby={modal_id}
      >
        <Modal.Header closeButton>
          <Modal.Title id={modal_id}>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
};

export default Dialog;
