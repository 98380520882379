import React, { useContext } from 'react'
import Context from '../context/Context'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
/* 
   * @Footer contains footer links item
   * @item conains link title and className
   * @Link routes you to specified route
   * this jsx section creates Footer Navigation using FooterState
   * we can modify the footer text , links. routing path etc from FooterState inside the Context/State.js.
 */

const Footer = () => {
  const { footerState } = useContext(Context)
  return (
    <Nav className="flex-column footer _footer">
      <Container fluid>
        {footerState.map(item =>
          <li style={{ padding: "14px" }} className={item.className} key={Math.random()}>
            <Link to={item.link} >
              <span>{item.title} </span>
            </Link>
          </li>
        )}
      </Container>
    </Nav>
  )
}
export default Footer;







