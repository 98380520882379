import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Actions from "../actions/form-actions";
import "./stylesheet/campaign.css";
import Form from "react-bootstrap/Form";
const Currency = () => {
  const [alert, setalert] = useState(false);
  const [alertMsg, setalertMsg] = useState("");
  const [curriences, setCurrencies] = useState({});
  const [curriencesMetaData, setCurrenciesMetaData] = useState({});

  useEffect(() => {
    try {
      Actions.data("currency", function (documents) {
        var obj = {};
        for (var key in documents) {
          obj = documents[key];
        }
        delete obj.id;
        setCurrencies(obj);
      });
      Actions.data("currencyMetaData", function (documents) {
        var obj = {};
        for (var key in documents) {
          obj = documents[key];
        }
        delete obj.id;
        setCurrenciesMetaData(obj);
      });
    } catch (error) {}
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    try {
      if (
        Actions.validate(curriences.AED) &&
        Actions.validate(curriences.BHD) &&
        Actions.validate(curriences.INR) &&
        Actions.validate(curriences.KWD) &&
        Actions.validate(curriences.OMR) &&
        Actions.validate(curriences.SAR) &&
        Actions.validate(curriences.PKR)
      ) {
        Actions.saveWithId("currency", "7onCEN3PmYmLl4zHm4RU", curriences);
        Actions.showAlert(
          "Information has been saved",
          setalertMsg,
          setalert,
          "2000"
        );
        setCurrencies({});
      } else {
        Actions.showAlert(
          "Please proper fill the fields  ",
          setalertMsg,
          setalert,
          "2000"
        );
      }
    } catch (error) {}
  };

  const handleChange = (e) => {
    var regex = /^\d*[.]?\d*$/;
    if (regex.test(e.target.value)) {
      setCurrencies({
        ...curriences,
        [e.target.name]: e.target.value,
      });
    } else {
      Actions.showAlert("Invalid entry  ", setalertMsg, setalert, "1000");
    }
  };
  const FormFeedback = (props) => {
    return (
      <Form.Control.Feedback type="invalid">
        {props.message}
      </Form.Control.Feedback>
    );
  };

  return (
    <>
      {alert ? (
        <div
          style={{ backgroundColor: "#e14eca", width: "50%" }}
          className="alert alert-danger d-flex align-self-center justify-content-center  "
          role="alert"
        >
          <strong>{alertMsg} </strong>
        </div>
      ) : (
        ""
      )}
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <>
                <Card.Title className="d-flex justify-content-center">
                  <p>
                    Currency Update <b> {curriencesMetaData["date"]}</b>
                  </p>
                </Card.Title>
                <Card.Title className="d-flex justify-content-center">
                  <h4 className={""}> 1 USD Rate </h4>
                </Card.Title>
                <>
                  <Form noValidate>
                    {/* gernal info */}

                    <Form.Row className="d-flex justify-content-center">
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}>
                          AED - UAE Dirham Equalalient to USD
                        </Form.Label>
                        <Form.Control
                          key={Math.random()}
                          value={curriences.AED}
                          onChange={handleChange}
                          type={"text"}
                          name={"AED"}
                          placeholder={"Enter UAE Dirham"}
                        />
                        <FormFeedback message={"Please provide  UAE Dirham."} />
                      </Form.Group>
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}>
                          BHD - Bahraini Dinar Equalalient to USD
                        </Form.Label>
                        <Form.Control
                          key={Math.random()}
                          value={curriences.BHD}
                          onChange={handleChange}
                          type={"text"}
                          name={"BHD"}
                          placeholder={" Bahraini Dinar Ruppee"}
                        />
                        <FormFeedback
                          message={"Please provide  Bahraini Dinar."}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="d-flex justify-content-center">
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}>
                          INR - Indian Rupee Equalalient to USD
                        </Form.Label>
                        <Form.Control
                          key={Math.random()}
                          value={curriences.INR}
                          onChange={handleChange}
                          type={"text"}
                          name={"INR"}
                          placeholder={"Enter INR - Indian Rupee"}
                        />
                        <FormFeedback
                          message={"Please provide   Indian Rupee"}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}>
                          KWD - Kuwaiti Dinar Equalalient to USD
                        </Form.Label>
                        <Form.Control
                          key={Math.random()}
                          value={curriences.KWD}
                          onChange={handleChange}
                          type={"text"}
                          name={"KWD"}
                          placeholder={" Enter KWD -  Kuwaiti Dinar "}
                        />
                        <FormFeedback
                          message={"Please provide  Bahraini Dinar."}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="d-flex justify-content-center">
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}>
                          OMR - Omani Rial Equalalient to USD
                        </Form.Label>
                        <Form.Control
                          key={Math.random()}
                          value={curriences.OMR}
                          onChange={handleChange}
                          type={"text"}
                          name={"OMR"}
                          placeholder={"Enter OMR - Omani Rial"}
                        />
                        <FormFeedback
                          message={"Please provide OMR - Omani Rial"}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}>
                          SAR - Saudi Riyal Equalalient to USD
                        </Form.Label>
                        <Form.Control
                          key={Math.random()}
                          value={curriences.SAR}
                          onChange={handleChange}
                          type={"text"}
                          name={"SAR"}
                          placeholder={"Enter SAR - Saudi Riyal"}
                        />
                        <FormFeedback
                          message={"Please provide  SAR - Saudi Riyal"}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="d-flex justify-content-center">
                      <Form.Group as={Col} md={4}>
                        <Form.Label className={""}>
                          PKR - Pakistani Rupee to USD
                        </Form.Label>
                        <Form.Control
                          key={Math.random()}
                          value={curriences.PKR}
                          onChange={handleChange}
                          type={"text"}
                          name={"PKR"}
                          placeholder={"Enter PKR - Pakistani Rupee"}
                        />
                        <FormFeedback
                          message={"Please provide PKR - Pakistani Rupee"}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Form>
                  <div className="d-flex justify-content-center">
                    <Button onClick={handleSave}>{"Update"}</Button>
                  </div>
                </>
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Currency;
