import axios from "axios"; 
import generateHash from "../../helpers/hash";
import { Firestore } from "./firebase-firestore";

/**
 * [title]: Notification Title
 * [body]: Notification Text
 * [tokens]: array of device tokens
 */
const sendMessage = (title, body, image, tokens) => {
  var data = JSON.stringify({
    title: title,
    body: body,
    image: image,
    tokens: tokens,
  });

  var config = {
    method: "post",
    url: "https://ebigwin.herokuapp.com/send/notification",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      Firestore.saveWithId("notifications", "notifications", {
        [generateHash()]: {
          icon: "https://cdn.icon-icons.com/icons2/691/PNG/128/google_firebase_icon-icons.com_61475.png",
          title: title,
          body: body,
          image: image,
        },
      });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default sendMessage;
