import React, { useState, useEffect } from "react";
import { Button, Col, ProgressBar, Row } from "react-bootstrap";
import { NotificationsModel } from "../../../models/notifications-model";
import "./stylesheet/notifications.css";
import { Form } from "react-bootstrap";
import sendMessage from "../../../dao/firebase/fcm";
import { Firestore } from "../../../dao/firebase/firebase-firestore";
import Dialog from "../../dialog/dialog";
import Flex, { FlexProps } from "../../flex-box/flex";
import NotificationCard from "./components/card";
const FormFeedback = (props) => {
  return (
    <Form.Control.Feedback type="invalid">
      {props.message}
    </Form.Control.Feedback>
  );
};

const NotificationsPage = () => {
  const [validated, setValidated] = useState(false);
  const [notification, setNotificaion] = useState(NotificationsModel);
  const [tokens, setTokens] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);

  useEffect(() => {
    getTokens();
  }, []);
  const uploadFile = (event) => {
    setShowDialog(true);
    var file = event.target.files[0];
    const name = event.target.name;

    Firestore.upload(
      "notifications",
      file,
      (snapshot) => {
        var percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        if (fileProgress < 100) {
          setFileProgress(percentage);
        }
      },
      (error) => {
        console.log(`error`, error);
        setShowDialog(false);
      },
      (fileURL) => {
        setNotificaion({
          ...notification,
          image: fileURL,
        });

        setShowDialog(false);
        setFileProgress(0);
      }
    );
  };
  const getTokens = async () => {
    const { id, data } = await Firestore.documentAsync("tokens", "all_tokens");
    setTokens(Object.values(data));
  };

  const setFormData = (e) => {
    setNotificaion({
      ...notification,
      [e.target.name]: e.target.value,
    });
    console.log(`notification`, notification);
  };

  const sendNotification = (e) => {
    e.preventDefault();
    sendMessage(
      notification.title,
      notification.body,
      notification.image,
      tokens
    );
  };

  return (
    <>
      {showDialog ? (
        <Dialog title={"Image Uploading"} show={showDialog}>
          <Flex
            justifyContent={FlexProps.justifyContent.center}
            alignItems={FlexProps.alignItems.center}
          >
            <ProgressBar
              variant="success"
              min={0}
              max={100}
              now={fileProgress}
            />
          </Flex>
        </Dialog>
      ) : (
        <></>
      )}

      <Row>
        <Col md={8}>
          <Form>
            <Form.Row>
              <Form.Group as={Col} md={12}>
                <Form.Label>Notifications Title</Form.Label>
                <Form.Control
                  onChange={setFormData}
                  type={"text"}
                  name={"title"}
                  placeholder={"Enter page Title"}
                />
                <FormFeedback message={"Please provide a valid page title."} />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={12}>
                <Form.Label>Notifications Title</Form.Label>
                <Form.Control
                  onChange={setFormData}
                  type={"text"}
                  name={"body"}
                  placeholder={"Enter Notification"}
                />
                <FormFeedback
                  message={"Please provide a valid page content."}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={12}>
                <Form.Label>Notifications Image</Form.Label>
                <Form.Control
                  onChange={setFormData}
                  type={"text"}
                  name={"image"}
                  placeholder={"Enter Image URL"}
                />
                <FormFeedback
                  message={"Please provide a valid notification image."}
                />
              </Form.Group>
            </Form.Row>

            <div>
              <h3>
                <b>Or</b>
              </h3>
            </div>

            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className={"btn btn-primary"}>
                  Product Image
                </Form.Label>

                <Form.Control
                  onChange={uploadFile}
                  type={"file"}
                  className={"btn btn-primary"}
                  accept={".gif,.jpg,.jpeg,.png"}
                  name={"product.image"}
                />

                <FormFeedback
                  message={"Please provide a valid product image."}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
        <Col md={4}>
          <NotificationCard
            notification={notification}
            onSend={sendNotification}
          />
        </Col>
      </Row>
    </>
  );
};

export default NotificationsPage;
