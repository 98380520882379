import firebase from 'firebase'
import { Callbacks } from 'jquery'
export const Auth = {
    // firebase helper methods go here... 
    signup: (email, password, setErrors, setToken,callback) => {
        firebase.auth().createUserWithEmailAndPassword(email, password)
            //make res asynchronous so that we can make grab the token before saving it.
            .then(async res => {
                const token = await Object.entries(res.user)[5][1].b
                    //set token to localStorage 
                // await localStorage.setItem('token', token)
                    //grab token from local storage and set to state. 
            //   setToken(window.localStorage.token)
            callback(true) 
            })
            .catch(err => {
                callback(false) 
                setErrors(prev => ([...prev, err.message]))
            })
    },
    signin: (email, password, setErrors, setToken) => {
        //change from create users to...
        firebase.auth().signInWithEmailAndPassword(email, password)
            //everything is almost exactly the same as the function above
            .then(async res => {
                const token = await Object.entries(res.user)[5][1].b
                    //set token to localStorage 
                await localStorage.setItem('token', token)
                setToken(window.localStorage.token)
                console.log(res)
            })
            .catch(err => {
                setErrors(prev => ([...prev, err.message]))
            })
    },
    signout: (setErrors, setToken) => {
        // signOut is a no argument function
        firebase.auth().signOut().then(res => {
                //remove the token
                localStorage.removeItem('token')
                    //set the token back to original state
                setToken(null)
                console.log("here is token is going to be null in Auth")
            })
            .catch(err => {
                //there shouldn't every be an error from firebase but just in case
                setErrors(prev => ([...prev, err.message]))
                    //whether firebase does the trick or not i want my user to do there thing.
                localStorage.removeItem('token')
                setToken(null)
                console.error(err.message)
            })
    },
}